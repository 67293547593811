class Author {
    constructor() {
        this.userId = null
        this.firstName = 'Unknown'
        this.lastName = 'Unknown'
        this.picture = 'https://cdn.vpapps.cloud/unnamed.jpg'
        this.email = null
        this.name = null
    }

    fromData(data) {

        if (!data) return

        this.userId = data.userId
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.picture = data.picture
        this.email = data.email
    }

    fromUserToAuthor(data) {
        if (!data) return

        this.firstName = data.name
        this.lastName = data.surname || data.lastName
        // this.lastName = data.lastName
        this.picture = data.picture
        this.email = data.email
        this.name = `${this.firstName} ${this.lastName}`
    }
}

export default Author