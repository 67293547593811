import FooterColumn from './FooterColumn';

class FooterRow {
    constructor(){
        this.id = null
        this.style = null
        this.children = null
        this.type = null
        this.props = null
    }

    fromData(data){
        // console.log('_TEST_', data)
        this.id = data.id
        this.style = data.style
        this.children = data.children?.map(column => {
            const footerColumn = new FooterColumn()
            footerColumn.fromData(column)

            return footerColumn
        })
        this.type = data.type
        this.props = {
            xAlignment: data.props.xAlignment,
            yAlignment: data.props.yAlignment,
        }
    }
}

export default FooterRow