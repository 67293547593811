import IconSettings from './../../icon/IconSettings';

class Search {
    constructor() {
        this.placeholderText = null
        this.iconSettings = null
    }

    fromData(data) {
        this.placeholderText = data.placeholderText
        
        // TODO: change this
        const iconSettings = new IconSettings()
        iconSettings.fromData(data.iconSettings)
        this.iconSettings = iconSettings

    }
}

export default Search