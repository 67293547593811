<script setup>
import { computed } from 'vue'
import Logo from './Logo.vue'

import { formatIconStyle } from '~/helpers/icon/icon'
import { formatComponentStyle } from '@/models/layout/style/component'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  breakpoint: {
    type: String,
    required: true,
  },
  isOpen: {
    type: Boolean,
    required: true,
  },
  onToggle: {
    type: Function,
    required: true,
  },
  onClose: {
    type: Function,
    default: () => {},
    required: false,
  },
})

const COMPONENT_NAMES = {
  menu: 'HeaderV2Menu',
  label: 'HeaderV2Label',
  language: 'HeaderV2Language',
  logo: 'HeaderV2Logo',
  spacing: 'HeaderV2Spacing',
}

const style = computed(() => formatComponentStyle(props.data.style))
const overlayStyle = computed(() =>
  formatComponentStyle({ background: props.data.style.overlay })
)

const mainIconStyle = computed(() => {
  const { style } = formatIconStyle(props.data.components.main)
  return style
})
const closeIconStyle = computed(() => {
  const { style } = formatIconStyle(props.data.components.close)
  return style
})

const alignment = computed(() => {
  const child = props.data.children.find((e) => e.type === 'logo')
  if (!child) return ''
  if (child.style.alignment === 'left') return 'logoAligmentLeft'
  if (child.style.alignment === 'right') return 'logoAligmentRight'
  if (child.style.alignment === 'center') return 'logoAligmentCenter'
})
</script>
<template>
  <Sidebar
    :data="data"
    :class="'order-' + data?.order"
    :is-open="isOpen"
    :on-toggle="onToggle"
    :on-close="onClose"
    :breakpoint="breakpoint"
    slide-direction="right"
    :main-icon-style="mainIconStyle"
    :main-icon-name="data.components.main.props.name"
    :close-icon-name="data.components.close.props.name"
    :close-icon-style="closeIconStyle"
    :style="style"
    :overlay-style="overlayStyle"
  >
    <template #header>
      <Logo
        v-if="data?.components?.logo"
        :breakpoint="breakpoint"
        :data="data.components.logo"
      />
    </template>
    <Identifier
      v-for="component in data.children"
      :data="component"
      :component="{ ...component, type: COMPONENT_NAMES[component.type] }"
      :breakpoint="breakpoint"
      :key="component.id"
      :class="alignment"
      is-nested
      @close-side-bar="onClose"
    />
  </Sidebar>
</template>

<style lang="scss" scoped>
::v-deep(.logoAligmentRight) {
  width: 100% !important;
  a {
    display: flex;
    justify-content: flex-end;
  }
}

::v-deep(.logoAligmentLeft) {
  width: 100% !important;
  a {
    display: flex;
    justify-content: flex-start;
  }
}

::v-deep(.logoAligmentCenter) {
  width: 100% !important;
  a {
    display: flex;
    justify-content: center;
  }
}
</style>
