class Banner {
    constructor() {
        this.name = 'Banner'
        this.bannerType = 1

        this.data = null
        this.socialMedia = { items: [] }
        this.properties = []
        this.settings = {}
        this.buttons = []

        this.isDynamic = false
    }

    fromData(data) {
        // console.error('BANNNNNNNNERR', data)

        if (!data) {
            throw ('Banner.js Error on data parse')
            return
        }

        this.bannerType = data.bannerType
        this.isDynamic = data.bannerType === 2

        this.data = data.data
        this.settings = data.settings
        this.properties = data.properties
        this.socialMedia = data.socialMedia
        this.buttons = data?.buttons || []


    }
}

export default Banner