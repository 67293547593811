import unhead_KgADcZ0jPj from "/webapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/webapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/webapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/webapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/webapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/webapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/webapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/webapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/webapp/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import errorHandling_MR23H4vQG8 from "/webapp/plugins/errorHandling.js";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  errorHandling_MR23H4vQG8
]