<script setup>
import { toRefs } from 'vue'
// import { useUrlBuilder } from "~/stores/url-builder";

// const urlBuilderStore = useUrlBuilder();

// const { getProjectId } = urlBuilderStore;

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  rowOrder: {
    type: Number,
    required: false,
    default: null
  },
})
const { component } = toRefs(props)

const getComponentName = (c) => {
  if (c) {
    return c.type
  }

  return 'div'
}
</script>

<template>
  <component :is="getComponentName(component)" :data="component" v-bind="$attrs" :rowOrder="rowOrder" />
</template>
