import Palette from "~/models/palette/Palette"

class ChatButton {
    constructor() {
        this.wrapper = {
            backgroundColor: "lightpink"
        }
    }

    fromData(data) {

        const palette = new Palette()
        palette.checkColor(data.fontProperties?.color)
        this.wrapper.backgroundColor = palette.color
        this.wrapper.color = '#e5e7eb'
    }
}

export default ChatButton