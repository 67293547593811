<script setup>
import { computed } from "vue";
import { JHeaderLogoV2 } from "media-flow-ui";

import { formatComponentStyle } from "@/models/layout/style/component";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  breakpoint: {
    type: String,
    required: true,
  },
});

const style = computed(() =>
  formatComponentStyle({
    ...props.data.style,
    width: null,
    height: null,
    backgroundColor: props.data.style?.backgroundColor || null,
  })
);
const imgStyle = computed(() =>
  formatComponentStyle({
    width: props.data.style?.width || { type: "px", value: 150 },
    height: props.data.style?.height,
    objectFit: props.data.style?.objectFit,
  })
);
</script>

<template>
  <JHeaderLogoV2
    :breakpoint="breakpoint"
    :data="data"
    :logoData="data"
    :class="'order-' + data.order"
    :custom-wrapper="data.props.linkType === 1 ? 'a' : 'RouterLink'"
    :style="style"
    :url="data.props.url"
    :img-source="data.props.source"
    :img-style="imgStyle"
  />
</template>

<style></style>
