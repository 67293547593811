class Video {
    constructor(){
        this.id = null
        this.videoSource = null
    }

    fromData(data){
        this.id = data.id
        this.videoSource = data.videoSource
    }
}

export default Video