import Palette from "../palette/Palette"

export class FontStyleModel {
  constructor() {
    this.fontSize = '14px'
    this.fontWeight = '500'
    this.fontFamily = 'Avant'
    this.fontStyle = 'Normal'
    this.color = '#333'
    this.textAlign = 'left'
    this.colorOnHover = ''
    this.colorOnActive = ''
  }

  fromData(data) {
    if (data.size) {
      this.fontSize = `${data.size}px`
    }

    const palette1 = new Palette()
    palette1.checkColor(data.colorOnHover)
    this.colorOnHover = palette1.color

    const palette2 = new Palette()
    palette2.checkColor(data.colorOnActive)
    this.colorOnActive = palette2.color

    this.fontWeight = data.weight
    this.fontFamily = data.family
    this.fontStyle = data.style

    const palette3 = new Palette()
    palette3.checkColor(data.color)
    this.color = palette3.color

    if (data.textAlignment) {
      this.textAlign = data.textAlignment
    } else {
      delete this.textAlign
    }
  }
}

export class FontModel {
  constructor() {
    this.size = 14
    this.weight = '500'
    this.family = 'Avant'
    this.style = 'Normal'
    this.color = ''
    this.colorOnHover = ''
    this.colorOnActive = ''
  }

  fromData({
    size = 14,
    weight = '500',
    family = 'Avant',
    style = 'Normal',
    color = '',
    colorOnHover = '',
    colorOnActive = '',
  }) {
    this.size = size
    this.weight = weight
    this.family = family
    this.style = style

    const palette1 = new Palette()
    palette1.checkColor(color)
    this.color = palette1.color

    const palette2 = new Palette()
    palette2.checkColor(colorOnHover)
    this.colorOnHover = palette2.color

    const palette3 = new Palette()
    palette3.checkColor(colorOnActive)
    this.colorOnActive = palette3.color
  }
}

export default FontStyleModel
