import Palette from '~/models/palette/Palette'
import { getGradient } from '@/models/general/GradientModel'

export class HeaderStyleModel {
  constructor() {
    this.background = ''
    this.backgroundColor = null
    this.opacity = null
  }

  fromData({
    backgroundColor = '',
    gradient = null,
    opacity = null,
  }) {

    const palette = new Palette()
    palette.checkColor(backgroundColor)
    this.backgroundColor = palette.color

    if (gradient) this.background = getGradient(gradient)
    this.opacity = opacity
  }
}
