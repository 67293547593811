import { IconModel } from '../../models/icon/IconModel'

export function formatIconStyle(data) {
  
  const i = new IconModel()

  if (!data) return i
  
  i.fromData({
    props: data.props,
    style: {
      ...data.style,
      size: data.props.size
    }
  })

  return i
}
