export const COOKIE_PREFIX = "auth._";
export const COOKIE_POSTFIX = ".iodc";

export const COOKIE_KEYS = [
  "profile",
  "access_token",
  "expires_at",
  "id_token",
  "refresh_token",
  "scope",
  "session_state",
  "state",
  "token_type",
];
