import Palette from '~/models/palette/Palette'

class LayoutStyle {
    constructor() {
        this.backgroundColor = null
        this.backgroundImage = null
        this.background = null
        this.omitHeaderFooter = false
    }

    fromData(data) {

        if (!data) return

        const palette = new Palette()
        palette.checkColor(data?.backgroundColor)
        this.backgroundColor = palette.color

        this.omitHeaderFooter = data.omitHeaderFooter

        if (data.backgroundImage && data.backgroundImage.length > 0) {
            this.backgroundImage = `url(${data.backgroundImage})`

        } else {
            delete this.backgroundImage
        }

        if (data.gradient && data.gradient.show) {
            const { show, type, direction, colors } = data.gradient
            const [first, second] = colors

            const palette1 = new Palette()
            palette1.checkColor(first.color)


            const palette2 = new Palette()
            palette2.checkColor(second.color)

            this.background = show
                ? `${type}(${direction}deg, ${palette1.color} ${first.position}px, ${palette2.color} ${second.position}px)`
                : ''
        } else {
            delete this.background
        }
    }
}

export default LayoutStyle