import Column from "./Column"
import Component from "./Component"

class Row {
    constructor(order) {
        this.order = order + 1
        this.children = []
        this.id = null
        this.props = null
        this.style = null
        this.type = 'row'
    }

    fromData(data) {

        this.children = data.children?.map(rawColumn => {
            const column = new Column()
            column.fromData(rawColumn)

            return column
        })

        this.id = data.id
        this.props = data.props
        this.style = data.style
        this.type = data.type
    }
}

export default Row