import ChatBody from "./components/ChatBody";
import ChatButton from "./components/ChatButton";
import ChatFooter from "./components/ChatFooter";
import ChatHeader from "./components/ChatHeader";
import ChatRow from "./components/ChatRow";
import ChatWrapper from "./components/ChatWrapper";

class ChatStyle {
  constructor() {
    this.wrapper = new ChatWrapper();

    this.header = new ChatHeader();

    this.body = new ChatBody();

    this.row = new ChatRow();

    this.footer = new ChatFooter();

    this.button = new ChatButton();
  }

  fromData(data) {
    if (!data) return;
    const w = new ChatWrapper();
    w.fromData(data.style);
    this.wrapper = w;

    const s = new ChatHeader();
    s.fromData({
      title: data.components.title.style,
      hideButton: data.components.headerButton.style,
    });
    this.header = s;

    const r = new ChatRow();
    r.fromData({
      wrapper: data.components.message.style,
      image: data.components.messageImage.style,
      author: data.components.messageAuthor.style,
      text: data.components.message.style,
    });
    this.row = r;

    const f = new ChatFooter();
    f.fromData({
      input: data.components.messageBox.style,
      button: data.components.footerButton.style,
      icon: data.components.footerButton.icon,
      type: data.components.footerButton.props.type,
    });
    this.footer = f;

    const button = new ChatButton();
    button.fromData(data.components.footerButton.style);
    this.button = button;
  }
}

export default ChatStyle;
