import {
  MenuRowStyleModel,
  MenuBurgerStyleModel,
} from '@/models/header/v2/MenuModel'

export function formatMenuStyle(data, type = 'row') {
  const m =
    type === 'row' ? new MenuRowStyleModel() : new MenuBurgerStyleModel()
  m.fromData(data)

  return m
}
