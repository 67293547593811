// import { ButtonModel } from "~/models/general/ButtonModel"

import Palette from "~/models/palette/Palette"
import { formatComponentStyle } from '@/models/layout/style/component'
import { formatFontStyle } from "~/models/layout/style/font"
import { formatIconStyle } from "~/helpers/icon/icon"

class ChatFooter {
  constructor() {
    this.wrapper = {
      // backgroundColor: "lightblue",
      // color: "blue",
    }
    this.type='icon-text'
    this.input = {}
    this.button = {
      fontSize: "14px",
      color: "#36435E",
    }
    this.icon = {
      name: "vpapps-font-send-message-alt",
      style: {
        fontSize: "20px",
        color: "#36435E",
      },
      props: {
        name: "vpapps-font-send-message-alt",
        size: 20,
      },
    }
  }

  fromData({ input = null, button = null, icon = null,type=null }) {
    if (input) {
      this.input = {
        wrapper: {
          ...formatComponentStyle({
            ...input, fontProperties: null, mentionColor: input?.mentionColor
          })
          
        },
        fontStyle: {
          ...formatFontStyle(input.fontProperties),
      }
      }
        if (input && input.backgroundColor) {
        const palette = new Palette()
        palette.checkColor(input.backgroundColor)

        this.input.wrapper.backgroundColor = palette.color
        }
        this.type=type
        if(input && input.mentionColor) {
          const palette = new Palette()
          palette.checkColor(input.mentionColor)

          this.input.wrapper.mentionColor = palette.color
        }


      
  }

    if (button) {
      this.button = formatComponentStyle(button)
    }

    if (icon) {
      this.icon = formatIconStyle(icon)
    }
  }
}

// class InputModel {
//   constructor() {}

//   fromData({ fontProperties = null }) {
//     if (fontProperties) {
//       const font = formatFontStyle(fontProperties)
//       const {
//         fontSize,
//         fontWeight,
//         fontFamily,
//         fontStyle,
//         textDecorationLine,
//         textAlign,
//         lineHeight,
//         color,
//         colorOnHover,
//         colorOnActive,
//       } = font

//       if (fontSize) this.fontSize = fontSize
//       if (fontWeight) this.fontWeight = fontWeight
//       if (fontFamily) this.fontFamily = fontFamily
//       if (fontStyle) this.fontStyle = fontStyle
//       if (textDecorationLine) this.textDecorationLine = textDecorationLine
//       if (textAlign) this.textAlign = textAlign
//       if (lineHeight) this.lineHeight = lineHeight

//       if (color) {
//         this.color = color
//       }
//       if (colorOnActive) {
//         this.colorOnActive = colorOnActive
//       }
//       if (colorOnHover) {
//         this.colorOnHover = colorOnHover
//       }
//     }
//   }
// }

export default ChatFooter