import { formatHeight, formatWidth } from '~/models/layout/style'

import Palette from '~/models/palette/Palette'
import { formatFontStyle } from "~/models/layout/style/font"
import { getBorder } from '@/models/general/BorderModel'

class ChatHeader {
  constructor() {
    this.wrapper = {}
    this.title = new TitleModel()
    this.hideButton = new HideButtonModel()
  }

  fromData({ wrapper = {}, title = null, hideButton = null }) {
    this.wrapper = wrapper

    if (title) {
      const t = new TitleModel()
      t.fromData(title)
      this.title = t
    }
    
    if (hideButton) {
      const s = new HideButtonModel()
      s.fromData(hideButton)
      this.hideButton = s
    }
  }
}

class TitleModel {
  constructor() {
    this.color = null
    this.backgroundColor = null
    this.padding = null
    this.borderRadius = null
  }

  fromData({
    backgroundColor = null,
    alignment,
    width = null,
    height = null,
    margin = null,
    padding = null,
    borderProperties = null,
    fontProperties = null
  }) {

    if (alignment) this.textAlign = alignment

    if (backgroundColor) {
      const palette = new Palette()
      palette.checkColor(backgroundColor)
      this.backgroundColor = palette.color
    }

    if (borderProperties) {
      const { borderRadius, borderColor, borderStyle, borderWidth } = getBorder(borderProperties)
      this.borderRadius = borderRadius

      // const palette2 = new Palette()
      // palette2.checkColor(borderColor)
      this.borderColor = borderColor

      this.borderStyle = borderStyle || 'solid'
      this.borderWidth = borderWidth
    }

    if (padding)
      this.padding = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`

    if (margin)
      this.margin = `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`

    if (width) this.width = formatWidth(width)

    if (height) this.height = formatHeight(height)

    if (fontProperties) {
      const font = formatFontStyle(fontProperties)
      const {
        fontSize,
        fontWeight,
        fontFamily,
        fontStyle,
        textDecorationLine,
        textAlign,
        lineHeight,
        color,
        colorOnHover,
        colorOnActive,
      } = font

      if (fontSize) this.fontSize = fontSize
      if (fontWeight) this.fontWeight = fontWeight
      if (fontFamily) this.fontFamily = fontFamily
      if (fontStyle) this.fontStyle = fontStyle
      if (textDecorationLine) this.textDecorationLine = textDecorationLine
      if (textAlign) this.textAlign = textAlign
      if (lineHeight) this.lineHeight = lineHeight

      if (color) {
        this.color = color
      }
      if (colorOnActive) {
        this.colorOnActive = colorOnActive
      }
      if (colorOnHover) {
        this.colorOnHover = colorOnHover
      }
    }
  }
}

class HideButtonModel {
  constructor() {
    this.color = null
    this.backgroundColor = null
    this.padding = null
    this.borderRadius = null
  }

  fromData({
    backgroundColor = null,
    backgroundColorOnHover = null,
    alignment, width = null,
    height = null,
    margin = null,
    padding = null,
    borderProperties = null,
    fontProperties = null,
  }) {
    if (alignment) this.textAlign = alignment

    if (backgroundColor) {
      const palette = new Palette()
      palette.checkColor(backgroundColor)
      this.backgroundColor = palette.color
    }

    if (backgroundColorOnHover) {
      const palette = new Palette()
      palette.checkColor(backgroundColorOnHover)
      this.backgroundColorOnHover = palette.color
    }

    if (borderProperties) {
      const { borderRadius, borderColor, borderStyle, borderWidth } = getBorder(borderProperties)
      this.borderRadius = borderRadius

      // const palette2 = new Palette()
      // palette2.checkColor(borderColor)
      this.borderColor = borderColor

      this.borderStyle = borderStyle || 'solid'
      this.borderWidth = borderWidth
    }

    if (padding)
      this.padding = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`

    if (margin)
      this.margin = `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`

    if (width) this.width = formatWidth(width)

    if (height) this.height = formatHeight(height)

    if (fontProperties) {
      const font = formatFontStyle(fontProperties)
      const {
        fontSize,
        fontWeight,
        fontFamily,
        fontStyle,
        textDecorationLine,
        textAlign,
        lineHeight,
        color,
        colorOnHover,
        colorOnActive,
      } = font

      if (fontSize) this.fontSize = fontSize
      if (fontWeight) this.fontWeight = fontWeight
      if (fontFamily) this.fontFamily = fontFamily
      if (fontStyle) this.fontStyle = fontStyle
      if (textDecorationLine) this.textDecorationLine = textDecorationLine
      if (textAlign) this.textAlign = textAlign
      if (lineHeight) this.lineHeight = lineHeight

      if (color) {
        this.color = color
      }
      if (colorOnActive) {
        this.colorOnActive = colorOnActive
      }
      if (colorOnHover) {
        this.colorOnHover = colorOnHover
      }
    }
  }
}

export default ChatHeader