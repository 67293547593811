class Notification {
    constructor(){
        this.id = null
        this.title = ''
        this.typeId = 0
        this.name = ''
        this.img= "https://cdn.vpplayer.tech/agmipnzv/encode/vjsnkirh/thumbnails/standart.jpg"
        this.source= "/video-1"
        this.text= "Vojtěch Dyk o životních cyklech a zparchantělé době= Mlčel jsem, protože"
        this.date= "23/12/2022",
        this.read = false,
        this.redirectUrl = '/'
        this.subtitle = ''
        this.linkType= 0
    }

    // {
    //     "notificationId": "string",
    //     "title": "string",
    //     "redirectUrl": "string",
    //     "read": true,
    //     "resourceName": "string",
    //     "date": "2023-07-27T13:59:18.826Z",
    //     "imageUrl": "string",
    //     "notificationTypeId": 0
    //   }

    fromQuery(data) {
        this.title = data.title
        this.subtitle = data.subtitle
        this.id = data.id
        this.typeId = data.notificationTypeId
        this.name = data.resourceName
        this.img= data.image
        this.source= data.redirectUrl ? data.redirectUrl : '/not-set'
        this.text= data.title
        this.date= data.date
        this.read = data.read
        this.redirectUrl = data.redirectUrl
        this.linkType = data.linkType
    }

    fromRealTime(data) {
        this.id = data.id
        this.typeId = data.notificationTypeId
        this.name = data.title
        this.img= data.image
        this.source= data.redirectUrl ? data.redirectUrl : '/not-set'
        this.text= data.subtitle
        this.date= data.date
        this.read = data.read
        this.redirectUrl = data.redirectUrl 
        this.linkType = data.linkType 
    }
}

export default Notification