class Palette {
    constructor() {

        this.color = null
    }

    checkColor(data) {
        if (!data) return

        if (data.includes('#') || data.includes('rgb')) {
            this.color = data
        } else if (data.includes('color-') || data.includes('color_')) {
            this.color = `var(--${data})`
        } else {
            return
        }

    }
}

export default Palette