class Media {
    constructor() {
        this.id = null
        this.title = null
        this.type = null
        this.category = null
        this.length = null
        this.tags = []
        this.show = {

        }
    }

    fromData(data) {
        this.id = data.id
        this.title = data.title
        this.type = data.type
        this.category = data.category
        this.length = data.length
        if (data.tags && data.tags.length > 0) {
            this.tags.push(...data.tags)
        }
        this.lockedAfter = data.lockedAfter
    }

    setTags(data) {
        if (!data && typeof data !== 'object') {
            return
        }

        const titles = [];

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const array = data[key];
                if (Array.isArray(array)) {
                    array.forEach(item => {
                        if (item && item.title) {
                            titles.push(item.title);
                        }
                    });
                }
            }
        }

        this.tags.push(...titles)
    }
}

export default Media