class Components {
    constructor(){
        this.logo = false
        this.menu = false
        this.search = false
        this.language = false
        this.notifications = false
        this.profile = false
    }

    fromData(data){
        this.logo = data.logo
        this.menu = data?.menu
        this.search = data.search
        this.language = data.language
        this.notifications = data.notifications
        this.profile = data.profile
    }
}

export default Components