import { isValidUrl } from "~/utils/isValidUrl"

class Script {
    constructor() {
        this.id = null
        this.name = null
        this.src = ''
        this.async = false
        this.defer = false
        this.body = false
        this.type = null
        this.tagPosition = null
        this.loadOption = 'once'
        this['custom-script'] = 'true'
        this.class = null
        this.isSSR = false
    }

    fromData(data) {

        let isValidScriptUrl = false

        if (data.downloadUrl) {
            isValidScriptUrl = isValidUrl(data.downloadUrl)
        }

        if (isValidScriptUrl) {
            this.src = data.downloadUrl
            this.type = 'application/javascript'

        } else {
            this.type = 'text/javascript'

            delete this.src
        }

        if (data.content) {
            this.innerHTML = data.content
        }

        if (data.custom) {
            this[data.custom.key] = data.custom.value
        }

        if (data.loadOption) {
            this.loadOption = data.loadOption

            if (data.loadOption === 'onceineachpage') {
                this.class = 'load-every-time-script'
                // this.date = new Date().toLocaleString();
            }

            if (data.loadOption === 'once') {
                this.class = 'load-one-time-script'
            }
        }

        this.name = data.name
        this.isSSR = data.isSSR

        // this.async = data.async
        // this.defer = data.defer

        if (data.location) {
            if (data.location === 'header') {
                this.body = false
                this.tagPosition = 'head'
            } else if (data.location === 'bodystart') {
                this.body = true
                this.tagPosition = 'bodyOpen'

            } else if (data.location === 'bodyend') {
                this.body = true
                this.tagPosition = 'bodyClose'

            } else {
                this.body = true
                this.tagPosition = 'bodyClose'
            }

        }
    }
}

export default Script