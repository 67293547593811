class AnalyticsUser {
    constructor() {
        this.id = 1
        this.uid = 0
        this.loginStatus = 'anonymous'
        this.premiumStatus = 'free'
        this.intrenal = 'ip'
        this.userId = null
        this.token = null
    }

    setLoginStatus(data) {
        this.loginStatus = data
    }

    setUid(data) {
        this.uid = data.id
    }

    setPremiumStatus(data) {
        this.premiumStatus = data
    }

    setUserId(data) {
        this.userId = data
    }

    setUserToken(data) {
        this.token = data
    }
}

export default AnalyticsUser