class PageMenuItem {
    constructor(){
        this.id = null
        this.url = null
        this.order =null
        this.itemId = null
        this.isExternal = false
        this.displayName = null
    }

    fromData(data){
        this.id = data.id

        this.url = data.url ? data.url : '/'
        
        this.order = data.order
        this.itemId = data.itemId
        this.isExternal = data.itemTypeId === 1 ? false : true
        this.displayName = data.navigationLabel

        if (data.itemTypeId === 2 && data.itemLinkTypeId === 2) this.isExternal = false
    }
}

export default PageMenuItem