import HeaderWrapper from "./HeaderWrapper"

class Header {
    constructor(deviceType) {
        this.deviceType = deviceType
        this.desktop = null
        this.mobile = null
        this.tablet = null
    }

    fromData(data) {

        if (!data) {
            throw ('No header data')
        }

        const desktopHeaderWrapper = new HeaderWrapper()
        desktopHeaderWrapper.fromData(data.desktop)
        this.desktop = desktopHeaderWrapper

        const mobileHeaderWrapper = new HeaderWrapper()
        mobileHeaderWrapper.fromData(data.mobile)
        this.mobile = mobileHeaderWrapper

        const tabletHeaderWrapper = new HeaderWrapper()
        tabletHeaderWrapper.fromData(data.tablet)
        this.tablet = tabletHeaderWrapper
    }

    fromDataV2(data) {

        if (!data) return null

        const deviceType = this.deviceType

        this[deviceType] = data.layout?.[deviceType]
        this[deviceType]?.components.map((comp, i) => {
            comp.order = i
            return comp
        })

        // this.desktop = data.layout?.desktop
        // this.desktop?.components.map((comp, i) => {
        //     comp.order = i
        //     return comp
        // })


        // this.mobile = data.layout?.mobile
        // this.mobile?.components.map((comp, i) => {
        //     comp.order = i
        //     return comp
        // })

        // this.tablet = data.layout?.tablet
        // this.tablet?.components.map((comp, i) => {
        //     comp.order = i
        //     return comp
        // })

    }
}

export default Header