export function formatMargin(data) {
  return data ? {
    margin: `${data.top}px ${data.right}px ${data.bottom}px ${data.left}px`
  } : null
}

export function formatPadding(data) {
  return data ? {
    padding: `${data.top}px ${data.right}px ${data.bottom}px ${data.left}px`
  } : null
}

export function formatBorderWidth(data) {
  return data ? {
    borderWidth: `${data.top}px ${data.right}px ${data.bottom}px ${data.left}px`
  } : null
}

export function formatWidth(data) {
  if (data) {
    const temp = typeof data === 'number' ? { value: data, type: '%' } : data
    return temp.type === 'px' || temp.type === '%' ? `${temp.value}${temp.type}` : `${temp.type}`
  }
  
  return null
}

export function formatHeight(height) {
 return height.type === 'px' || height.type === '%'
  ? `${height.value}${height.type}`
  : `${height.type}`
}

export function formatBorderRadius(data) {
  return data ? {
    borderRadius: `${data.top}px ${data.right}px ${data.bottom}px ${data.left}px`
  } : null
}

export function hexToRgb(hex, a = 100) {
  // Remove the "#" character if it's included
  hex = hex ? hex.replace(/^#/, '') : hex

  // Ensure the hex string is exactly 6 or 3 characters long
  if (!hex || (hex?.length !== 3 && hex?.length !== 6)) {
    return null
  }

  if (a === null || a === undefined || !Number.isInteger(a)) a = 100

  // If the hex string is 3 characters long, expand it to 6 characters
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }

  // Parse the hexadecimal values and convert them to decimal
  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)

  // Return the RGB values as an object
  return `rgba(${r}, ${g}, ${b}, ${a / 100})`
}