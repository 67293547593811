import LayoutStyle from "./LayoutStyle";
import Row from "./Row";
import Script from "./script/Script";
import StructuredData from "./analytics/StructuredData";
import mediaLayout from "~~/demoData/mediaLayout";
import notFoundLayout from "~~/demoData/notFoundLayout";
import { removeHtmlTagsAndDecode } from "~~/utils/removeHtmlTagsAndDecode";
import searchLayout from "~~/demoData/searchLayout";
import { storeToRefs } from "pinia";
import { useUrlBuilder } from "~~/stores/url-builder";

// const urlBuilderStore = useUrlBuilder();
// const { getProjectId } = storeToRefs(urlBuilderStore);


class Layout {
  constructor(deviceType) {
    // this.layout = []
    this.deviceType = deviceType
    this.id = 0;
    this.inputs = {};
    this.media = {};
    this.seo = {};
    this.content = null;
    this.layout = {
      desktop: {},
      tablet: {},
      mobile: {},
    };
    this.queries = [];
    this.contentId = "";

    this.scripts = [];

    this.structuredData = new StructuredData();
    this.analyticsProperties = {}
    this.user = null
  }

  fromData(data, demo = false) {
    const deviceType = this.deviceType

    const urlBuilderStore = useUrlBuilder();
    const { isCLH, config, getProjectId } = storeToRefs(urlBuilderStore);

    if (data && data.analyticsProperties) {
      this.analyticsProperties = data.analyticsProperties
    }

    if (!isCLH.value) {
      const serverScripts = data?.customScripts || [];

      // serverScripts.unshift({
      //   downloadUrl: config.value.public.analyticsUrl,
      //   location: "body",
      //   loadOption: "once",
      //   custom: {
      //     key: 'handler',
      //     value: 'eval',
      //   }
      // });

      this.scripts = serverScripts?.map((s) => {
        const script = new Script();
        script.fromData(s);

        return script;
      });
    }

    if (!data) return;

    this.queries = data?.queries || [];

    this.layout[deviceType].rows = data.layout[deviceType]?.rows?.map((rawRow, index) => {
      const row = new Row(index);
      row.fromData(rawRow);


      return row;
    });

    const deviceStyle = new LayoutStyle();
    deviceStyle.fromData(data.layout[deviceType]?.style);
    this.layout[deviceType].style = deviceStyle;


    this.id = data.id;
    this.inputs = data.inputs;
    this.media = data.media;
    this.content = data.content;

    if (data.content) {
      this.pageContentId = data.content.id;

      const seoTitle = replaceWithinBrackets(data.seo.title, data);
      const seoDescription = replaceWithinBrackets(data.seo.description, data);
      const seoAuthor = replaceWithinBrackets(data.seo.author, data);

      const seoMetaOgTitle = replaceWithinBrackets(data.seo.metaOg?.title, data);
      const seoMetaOgDescription = replaceWithinBrackets(
        data.seo.metaOg?.description,
        data
      );
      const seoMetaOgImage = replaceWithinBrackets(data.seo.metaOg?.image, data);

      const seoTwitterTitle = replaceWithinBrackets(
        data.seo.twitterCard?.title,
        data
      );
      const seoTwitterDescription = replaceWithinBrackets(
        data.seo.twitterCard?.description,
        data
      );
      const seoTwitterImage = replaceWithinBrackets(
        data.seo.twitterCard?.image,
        data
      );

      this.seo = {
        title: seoTitle,
        description: seoDescription || seoTitle,
        author: seoAuthor,
        metaOg: {
          title: seoMetaOgTitle,
          description: seoMetaOgDescription || seoMetaOgTitle,
          image: data?.content?.bannerImages?.desktop,
        },
        twitterCard: {
          title: seoTwitterTitle,
          description: seoTwitterDescription || seoTwitterTitle,
          image: data?.content?.bannerImages?.desktop,
        },
      };
    } else if (data.media && data.media.id) {
      // this.analyticsProperties.mediaId = data.media.id
      // this.analyticsPropertiess.setMediaId(data.media.id)
      this.structuredData.fromData(data.media);

      let description = removeHtmlTagsAndDecode(data.media?.model?.description);

      const title = data?.media.model?.title;

      let image = ''
      if (data.media.model.imageAssets && data.media.model.imageAssets.length > 0) {
        image = data?.media.model?.imageAssets[0].url;
      }

      if (!description) description = title;

      this.seo = {
        title: title || '',
        description: description,
        author: data.media?.model?.author,
        metaOg: {
          title: title,
          description: description,
          image: image,
        },
        twitterCard: {
          title: title,
          description: description,
          image: image,
        },
      };
    } else {
      this.seo = data.seo;
    }

    if (this.seo && data.seo && data.seo.robot) {
      this.seo.robot = data.seo.robot
    }
    this.user = data.user
  }

  getRootStyle() {
    return this.layout[this.deviceType].style
  }

  fromNoData() {
    this.seo = {
      title: 'Page Not Found',
      description: 'This page does not exists or problem finding.'
    }

    // this.layout = notFoundLayout.layout
  }
}

export default Layout;
