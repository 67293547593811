import Palette from "../palette/Palette"

export class GradientModel {
  constructor() {
    this.show = false
    this.type = 'linear-gradient'
    this.direction = 0
    this.colors = [
      { color: '', position: 0 },
      { color: '', position: 0 },
    ]
  }

  fromData({
    show = false,
    type = 'linear-gradient',
    direction = 0,
    colors = null,
  }) {
    this.show = show
    this.type = type
    this.direction = direction
    if (colors) this.colors = colors
  }
}

export function getGradient(gradient) {
  const { show, type, direction, colors } = gradient
  const [first, second] = colors

  const palette1  = new Palette()
  palette1.checkColor(first.color)
  const firstColor = palette1.color

  const palette2  = new Palette()
  palette2.checkColor(second.color)
  const secondColor = palette2.color


  return show
    ? `${type}(${type === 'linear-gradient' ? direction + 'deg, ' : ''}${
      firstColor
      } ${first.position}%, ${secondColor} ${second.position}%)`
    : ''
}
