import Component from "./Component"
import Palette from "../palette/Palette"

class Column {
  constructor() {
    this.children = []
    this.id = null
    this.props = null
    this.style = null
    this.type = 'col'
  }

  fromData(data) {
    if (data.children && data.children.length > 0) {
      this.children = data.children.map(rawComponent => {
        const component = new Component()
        component.fromData(rawComponent)
        return component
      })
    } else {
      console.error('Column.js No children')
    }

    this.id = data.id
    this.props = data.props
    this.style = data.style
    this.type = data.type
  }
}

export class ColumnStyleModel {
  constructor() {
    this.padding = null
    this.margin = null
    this.backgroundColor = null
  }

  fromData({ padding = null, margin = null, backgroundColor = null, maxWidth = null }) {
    if (padding)
      this.padding = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`

    if (margin)
      this.margin = `${margin.top}px 0px ${margin.bottom}px 0px`

    const palette = new Palette()
    palette.checkColor(backgroundColor)
    this.backgroundColor = palette.color
  }
}

export default Column