import Font from '../font/Font';
import Image from "../image/Image"
import Palette from '../palette/Palette';

class FooterComponent {
  constructor() {
    this.id = null
    this.type = null
    this.style = null
    this.props = null
  }

  fromData(data) {
    this.id = data.id

    this.type = data.type
    this.props = data.props
    this.style = data.style

    if (data.type === 'label') {
      const style = new Font()
      style.fromData(data.style.fontProperties)
      this.style = style
    } else if (data.type === 'menu') {
      
      const palette1 = new Palette()
      palette1.checkColor(data.style.bgColor)
      const wrapperStyle = {
        backgroundColor: palette1.color,
        textAlign: data.style.alignment
      }

      const itemsStyle = new Font()
      if (data.style.itemsStyle)
        itemsStyle.fromData(data.style.itemsStyle.fontStyle)

      const orientation = data.style.itemsStyle?.orientation


      const titleStyle = new Font()
      if (data.style.titleStyle)
        titleStyle.fromData(data.style.titleStyle.fontStyle)

      this.style = {
        wrapperStyle: wrapperStyle,
        titleStyle: titleStyle,
        itemsStyle: {
          orientation,
          ...itemsStyle,
          ...(data.style?.itemsStyle.orientation === 'horizontal' ? { justifyContent: data.style.alignment } : {})
        },
      }
    } else if (data.type === 'SocialMedia') {
      if (data.props.items.length > 0) {
        this.props.items = data.props.items.map(v => {
          v.icon = v.icon
          return v
        })
      }
    }
  }
}

export default FooterComponent