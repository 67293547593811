import Banner from "./Banner"

class Carousel {
    constructor() {
        this.name = 'carousel'
        this.autoswipe = 5
        this.elements = 2
        this.bannerType = 1
        this.indicators = null
        this.items = []
    }

    fromData(data) {
        this.name = 'carousel'
        this.autoswipe = data.autoswipe
        this.elements = data.elements
        this.indicators = data.indicators

        this.bannerType = data.bannerType
        this.isDynamic = data.bannerType === 2

        if (!data.items) {
            throw ('error on Crousel')
            return {}
        }

        this.items = data.items.map((staticBanner, index) => {

            const banner = new Banner()
            banner.fromData(staticBanner)

            return { ...banner, active: !index ? true : false }
        })
    }
}

export default Carousel