import {
  formatHeight,
  formatMargin,
  formatPadding,
  formatWidth,
  hexToRgb,
} from "./index";

import Palette from "~/models/palette/Palette";
import { formatFontStyle } from "./font";
import { getBorder } from "../../general/BorderModel";

class ComponentStyleModel {
  constructor() { }

  fromData({
    color = null,
    colorAlpha = 100,
    colorOnHover = "",
    colorOnHoverAlpha = 100,
    colorOnActive = "",
    colorOnActiveAlpha = 100,
    alignment = "",
    borderProperties = null,
    fontProperties = null,
    fontStyle = null,
    background = null,
    width = null,
    height = null,
    gap = 0,
    spacing = 0,
    aspectRatio = null,
    objectFit = null,
    xPos = 0,
    yPos = 0,
    padding = null,
    margin = null,
    blur = 0,
    backgroundColor = null,
    mentionColor = "",
    bgColor = "",
    bgColorOnHover = "",
    bgColorOnActive = "",
  }) {
    if (alignment) this.textAlign = alignment;
    if (gap || spacing) this.gap = `${gap || spacing}px`;
    if (aspectRatio) this.aspectRatio = aspectRatio;
    if (objectFit) this.objectFit = objectFit;
    if (yPos || xPos) this.objectPosition = `${xPos}px ${yPos}px`;

    // colors
    if (color) {
      // this.color = hexToRgb(color, colorAlpha)

      // const palette = new Palette()
      // palette.checkColor(color)
      this.color = color;
    }
    if (colorOnActive) {
      // this.colorOnActive = hexToRgb(colorOnActive, colorOnActiveAlpha)

      const palette = new Palette();
      palette.checkColor(colorOnActive);
      this.colorOnActive = palette.color;
    }
    if (colorOnHover) {
      // this.colorOnHover = hexToRgb(colorOnHover, colorOnHoverAlpha)

      const palette = new Palette();
      palette.checkColor(colorOnHover);
      this.colorOnHover = palette.color;
    }
    if (backgroundColor) {
      const palette = new Palette();
      palette.checkColor(backgroundColor);
      this.backgroundColor = palette.color;
    }

    // background colors
    if (background) {
      const {
        color,
        colorAlpha = 100,
        colorOnActive,
        colorOnActiveAlpha = 100,
        colorOnHover,
        colorOnHoverAlpha = 100,
      } = background;

      if (color) {
        const palette = new Palette();
        palette.checkColor(color);
        this.backgroundColor = palette.color;
      }

      if (colorOnActive) {
        const palette = new Palette();
        palette.checkColor(colorOnActive);
        this.backgroundColorOnActive = palette.color;
      }

      if (mentionColor) {
        const palette = new Palette();
        palette.checkColor(mentionColor);
        this.mentionColor = palette.color;
      }

      if (colorOnHover) {
        const palette = new Palette();
        palette.checkColor(colorOnHover);
        this.backgroundColorOnHover = palette.color;
      }
    }
    if (bgColor) {
      const palette = new Palette();
      palette.checkColor(bgColor);
      this.backgroundColor = palette.color;
    }

    if (bgColorOnActive) {
      const palette = new Palette();
      palette.checkColor(bgColorOnActive);
      this.backgroundColorOnActive = palette.color;
    }

    if (bgColorOnHover) {
      const palette = new Palette();
      palette.checkColor(bgColorOnHover);
      this.backgroundColorOnHover = palette.color;
    }
    // border
    if (borderProperties && borderProperties.show) {
      const {
        borderColor,
        borderStyle,
        borderRadius,
        borderWidth,
        borderColorOnHover,
        borderColorOnActive,
      } = getBorder(borderProperties);
      this.borderStyle = borderStyle;
      this.borderWidth = borderWidth;
      this.borderRadius = borderRadius;
      this.borderColorOnHover = borderColorOnHover;
      if (borderColorOnActive) this.borderColorOnActive = borderColorOnActive;
      this.borderColor = borderColor;
    }

    if (!fontProperties && fontStyle) fontProperties = fontStyle;
    // font
    if (fontProperties) {
      const font = formatFontStyle(fontProperties);
      const {
        fontSize,
        fontWeight,
        fontFamily,
        fontStyle,
        textDecorationLine,
        textAlign,
        lineHeight,
        color,
        colorAlpha,
        colorOnHover,
        colorOnHoverAlpha,
        colorOnActive,
        colorOnActiveAlpha,
      } = font;

      if (fontSize) this.fontSize = fontSize;
      if (fontWeight) this.fontWeight = fontWeight;
      if (fontFamily) this.fontFamily = fontFamily;
      if (fontStyle) this.fontStyle = fontStyle;
      if (textDecorationLine) this.textDecorationLine = textDecorationLine;
      if (textAlign) this.textAlign = textAlign;
      if (lineHeight) this.lineHeight = `${lineHeight}px`;

      if (color) {
        this.color = color;
      }
      if (colorOnActive) {
        this.colorOnActive = colorOnActive;
      }
      if (colorOnHover) {
        this.colorOnHover = colorOnHover;
      }
    }

    // width, height
    if (width) {
      const w = formatWidth(
        typeof width === "number" ? { value: width, type: "px" } : width
      );
      this.width = w;
    }

    if (height) {
      const h = formatHeight(
        typeof height === "number" ? { value: height, type: "px" } : height
      );
      this.height = h;
    }

    // margin, padding
    if (padding) {
      const temp = formatPadding(padding);
      this.padding = temp.padding;
    }
    if (margin) {
      const temp = formatMargin(margin);
      this.margin = temp.margin;
    }

    if (blur) {
      this.filter = `blur(${blur})px`;
    }
  }
}

export function formatComponentStyle(data) {
  const style = new ComponentStyleModel();
  style.fromData(data);

  return style;
}
