class User {
    constructor() {
        this.name = null
        this.email = null
        this.birthdate=null
        this.picture = null
        this.sid = null
        this.username = null
        this.surname = null
        this.gender = null
        this.accessToken = null
    }

    fromData(data){
        this.name = data.given_name
        this.surname = data.family_name
        this.email = data.email
        this.birthdate=data.birthdate
        this.picture = data.picture
        this.sid = data.sid
        this.username = data.name
        this.gender = data.gender
        this.accessToken = data.access_token
    }
}

export default User