import { DimensionValues } from './DimensionValues'
import Palette from '../palette/Palette'

export class BorderModel {
  constructor() {
    this.show = false
    this.color = 'transparent'
    this.colorAlpha = 100
    this.radius = new DimensionValues()
    this.width = new DimensionValues()
  }

  fromData({
    show = false,
    color = 'transparent',
    colorAlpha = 100,
    radius = null,
    width = null,
  }) {
    this.show = show

    const palette = new Palette()
    palette.checkColor(color)
    this.color = palette.color

    this.colorAlpha = colorAlpha

    const w = new DimensionValues()
    w.fromData(width || { top: 1, right: 1, bottom: 1, left: 1 })
    this.width = w

    if (radius) {
      const r = new DimensionValues()
      r.fromData(radius)
      this.radius = r
    }
  }
}

export function getBorder(border) {
  const { show, color, colorAlpha, radius, width, colorOnHover, colorOnHoverAlpha, colorOnActive, colorOnActiveAlpha } = border
  const {
    top: topLeftRadius,
    right: topRightRadius,
    bottom: bottomRightRadius,
    left: bottomLeftRadius,
  } = radius || {}

  let isWidthaNumber = false

  if (typeof width === 'number') {
    isWidthaNumber = true
  }

  const {
    top: topWidth = 0,
    right: rightWidth = 0,
    bottom: bottomWidth = 0,
    left: leftWidth = 0,
  } = width || {}

  const palette1 = new Palette()
  palette1.checkColor(color)
  const borderColor = palette1.color

  const palette2 = new Palette()
  palette2.checkColor(colorOnHover)
  const borderColorOnHover = palette2.color

  const palette3 = new Palette()
  palette3.checkColor(colorOnActive)
  const borderColorOnActive = palette3.color

  return {
    // borderColor: hexToRgb(color, colorAlpha),
    // borderColorOnHover: hexToRgb(colorOnHover, colorOnHoverAlpha),
    // borderColorOnActive: hexToRgb(colorOnActive, colorOnActiveAlpha),
    borderColor: borderColor,
    borderColorOnHover: borderColorOnHover,
    borderColorOnActive: borderColorOnActive,
    borderStyle: 'solid',
    borderRadius: `${topLeftRadius}px ${topRightRadius}px ${bottomRightRadius}px ${bottomLeftRadius}px`,
    borderWidth: isWidthaNumber ? `${width}px` : `${topWidth}px ${rightWidth}px ${bottomWidth}px ${leftWidth}px`
  }
}
