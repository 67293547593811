import Menu from './components/Menu';
import Search from './components/Search';
import Language from './components/Language';
import Profile from './components/Profile';
import Logo from './components/Logo';
import Notification from './components/Notification';

class HeaderComponents {
    constructor() {
        this.menu = null
        this.search = null
        this.logo = null
        this.language = null
        this.notifications = null
        this.profile = null
    }

    fromData(data) {
        const menu = new Menu()
        menu.fromData(data?.menu)
        this.menu = menu

        const search =  new Search()
        search.fromData(data.search)
        this.search = search

        const logo = new Logo()
        logo.fromData(data.logo)
        this.logo = logo

        const language = new Language()
        language.fromData(data.language)
        this.language = language

        const notification = new Notification()
        notification.fromData(data.notifications)
        this.notifications = notification

        const profile = new Profile()
        profile.fromData(data.profile)
        this.profile = profile
    }
}

export default HeaderComponents