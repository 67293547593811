import {
  SpacingStyleModel,
  DividerWrapperStyleModel,
  DividerStyleModel,
} from '~/models/spacing/SpacingModel'

export function formatSpacingStyle(data, type = 'space') {
  const s =
    type === 'space' ? new SpacingStyleModel() : new DividerWrapperStyleModel()
  s.fromData(data)

  return s
}

export function formatDividerStyle(data) {
  if (!data) return {}

  const s = new DividerStyleModel()
  s.fromData(data)

  return s
}

