import { BorderModel, getBorder } from '../general/BorderModel'

import { DimensionValues } from '../general/DimensionValues'
import Palette from '../palette/Palette'

export class IconModel {
  constructor(data) {
    this.style = new StyleModel()
    this.props = new PropsModel(data?.props || null)
  }

  fromData({ style = null, props = null }) {
    if (style) {
      const s = new StyleModel()
      s.fromData(style)
      this.style = s
    }

    if (props) {
      const p = new PropsModel()
      p.fromData(props)
      this.props = p
    }
  }
}

class PropsModel {
  constructor(data) {
    this.size = data?.size || 24
    this.name = data?.name || 'vpapps-font-heart-favorite'
  }

  fromData({ size = 24, name = 'vpapps-font-heart-favorite' }) {
    this.size = size || this.size
    this.name = name || this.name
  }
}

class StyleModel {
  constructor() {
    this.color = ''
    this.colorOnHover = ''
    this.colorOnActive = ''
    this.backgroundColor = ''
    this.backgroundColorOnHover = ''
    this.backgroundColorOnActive = ''
    this.padding = null
    this.margin = null
    this.fontSize = '18px'
  }

  fromData({
    color = null,
    colorAlpha = 100,
    colorOnHover = '',
    colorOnHoverAlpha = 100,
    colorOnActive = '',
    colorOnActiveAlpha = 100,
    background = null,
    bgColor = '',
    bgColorAlpha = 100,
    bgColorOnHover = null,
    bgColorOnHoverAlpha = 100,
    bgColorOnActive = null,
    bgColorOnActiveAlpha = 100,
    padding = null,
    margin = null,
    borderProperties = null,
    size = 18,
  }) {

    // this.color = hexToRgb(color, colorAlpha)
    const palette1 = new Palette()
    palette1.checkColor(color)
    this.color = palette1.color

    // this.colorOnHover = hexToRgb(colorOnHover, colorOnHoverAlpha)
    const palette2 = new Palette()
    palette2.checkColor(colorOnHover)
    this.colorOnHover = palette2.color

    // this.colorOnActive = hexToRgb(colorOnActive, colorOnActiveAlpha)
    const palette3 = new Palette()
    palette3.checkColor(colorOnActive)
    this.colorOnActive = palette3.color

    // this.backgroundColor = hexToRgb(bgColor, bgColorAlpha)
    const palette4 = new Palette()
    palette4.checkColor(background?.color)
    this.backgroundColor = palette4.color

    // this.backgroundColorOnHover = hexToRgb(bgColorOnHover, bgColorOnHoverAlpha)
    const palette5 = new Palette()
    palette5.checkColor(background?.colorOnHover)
    this.backgroundColorOnHover = palette5.color

    // this.backgroundColorOnActive = hexToRgb(
    //   bgColorOnActive,
    //   bgColorOnActiveAlpha,
    // ) 
    const palette6 = new Palette()
    palette6.checkColor(bgColorOnActive)
    this.backgroundColorOnActive = palette6.color

    this.fontSize = size + 'px'

    if (padding) {
      const p = new DimensionValues()
      p.fromData(padding)
      const { padding: pa } = p.getPadding()
      this.padding = pa
    }

    if (margin) {
      const ma = new DimensionValues()
      ma.fromData(margin)
      const { margin: mar } = ma.getMargin()
      this.margin = mar
    }

    if (borderProperties) {
      const { borderColor, borderStyle, borderRadius, borderWidth } =
        getBorder(borderProperties)
      this.borderStyle = borderStyle
      this.borderWidth = borderWidth
      this.borderRadius = borderRadius

      const palette7 = new Palette()
      palette7.checkColor(borderColor)
      this.borderColor = palette7.color
    }
  }
}