import { defineStore, storeToRefs } from "pinia"

import { CHAT_STATUS_TYPE } from "@/constants/chat"
import ChatMessage from "~/models/chat/ChatMessage"
import { HubConnectionBuilder } from "@microsoft/signalr"
import { usePageBuilder } from "~/stores/page-builder"
import { useUrlBuilder } from "~/stores/url-builder"

export const useSignalrBuilder = defineStore({
  id: "signalr-builder",
  state: () => {
    return {
      isConnected: false,
      user: null,
      userManager: null,
      realTimeMediaViews: 0,
      connection: null,
      realTimeNotification: null,
      realTimeUnregisteredNotification: null,
      joinQueue: [],
      chatMessages: [],
      chatConfig: {
        active: true,
        message: "Chat is not active",
        inReplayMode: false,
      },
      newestChatMessage: null,
      chatIdToRemove: null,
    }
  },
  actions: {
    async createConnection() {
      if (this.isConnected) return

      const config = useRuntimeConfig()
      const baseUrlRealtime = config.public.baseUrlRealtime

      const urlBuilderStore = useUrlBuilder()
      const { getToken } = storeToRefs(urlBuilderStore)

      const accessToken = getToken.value || null
      // console.log('token', accessToken)

      this.connection = new HubConnectionBuilder()
        .withUrl(`${baseUrlRealtime}/contentHub`, {
          accessTokenFactory: () => accessToken,
        })
        .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 59000])
        .build()

      // console.log('SignalR connected')
      try {
        await this.connection.start({ withCredentials: false })
        this.isConnected = true
        this.executeFromJoinQueue()
        // this.joinGroup(1)
      } catch (error) {
        console.error(error)
      }

      this.connection.on("ReceiveChatMessage", (message) => {
        this.setChatNotifications(message)
      })

      this.connection.on("ReceiveChatInfo", (message) => {
        // console.log(`ReceiveChatInfo: ${message.chatStatusId}`)
        this.updateChatInfo(message)
      })

      this.connection.on("DeleteChatMessage", (message) => {
        this.chatIdToRemove = message
      })

      this.connection.on("ReceiveNotifications", (message, id) => {
        // console.log(`ReceiveNotifications: ${message} - ${id}`, message, id)
        if (message.notificationDisplayType === 1) {
          this.setNotification(message)
        }
        if (message.notificationDisplayType === 2) {
          this.setUnregisteredUserNotification(message)
        }
      })

      this.connection.on("ReceiveComment", (message) => {
        // console.log(`ReceiveComment: ${message}`)
      })

      this.connection.on("ReceiveLiveViewers", (message) => {
        // console.log(`ReceiveLiveViewers: ${message}`)

        this.realTimeMediaViews = Number(message)
      })
    },
    async joinGroup({ connectionName, data }) {
      // console.log('SignalR hub invoke', { connectionName, data })

      if (this.connection) {
        this.connection.invoke(connectionName, data)
      } else {
        throw ("No connection not started, trying to joinGroup", data)
      }
    },
    async leaveGroup(data) {
      try {
        if (this.connection) {
          this.connection.invoke("LeaveRoom", data)
        } else {
          throw ("No connection not started, trying to leaveGroup", data)
        }
      } catch (error) {
        throw error
      }
    },
    setToJoinQueue(connectionName, data) {
      if (this.isConnected) {
        this.joinGroup({ connectionName, data })
      } else {
        this.joinQueue.push({ connectionName, data })
      }
    },
    executeFromJoinQueue() {
      this.joinQueue.forEach((element) => {
        this.joinGroup(element)
      })

      this.joinQueue = []
    },
    setNotification(data) {
      this.realTimeNotification = data
    },
    setChatNotifications(data) {
      const chatMessage = new ChatMessage()
      chatMessage.formData(data)
      // this.chatMessages.push(chatMessage)

      this.newestChatMessage = chatMessage
    },

    setUnregisteredUserNotification(data) {
      this.realTimeUnregisteredNotification = data
    },
    updateChatInfo(data) {
      if (CHAT_STATUS_TYPE.ACTIVE === data.chatStatusId) {
        this.chatConfig.active = true
        this.chatConfig.inReplayMode = false
      } else if (CHAT_STATUS_TYPE.INACTIVE === data.chatStatusId) {
        this.chatConfig.active = false
        this.chatConfig.inReplayMode = false
        this.chatConfig.message = "Chat has ended"
      } else if (CHAT_STATUS_TYPE.PAUSED === data.chatStatusId) {
        this.chatConfig.active = false
        this.chatConfig.inReplayMode = false
        this.chatConfig.message = "Chat has been paused"
      } else if (CHAT_STATUS_TYPE.SCHEDULED === data.chatStatusId) {
        this.chatConfig.active = false
        this.chatConfig.inReplayMode = false
        this.chatConfig.message = "Chat scheduled. Will start soon."
      } else if (CHAT_STATUS_TYPE.REPLAY === data.chatStatusId) {
        this.chatConfig.active = false
        this.chatConfig.inReplayMode = true
        this.chatConfig.message = "Chat replay"
      }
    },
    removeChatMessageById(data) {},
  },
})
