import StructuredData from "../layout/analytics/StructuredData";
import { removeHtmlTagsAndDecode } from "~~/utils/removeHtmlTagsAndDecode";

class SeoModel {
    constructor() {
        this.seo = null
        this.structuredData = new StructuredData()
    }

    fromData(data) {
        const { content, media, seo } = data ?? {}

        this.seo = seo

        if (content) {
            this.pageContentId = content.id;

            const seoTitle = replaceWithinBrackets(seo.title, data);
            const seoDescription = replaceWithinBrackets(seo.description, data);
            const seoAuthor = replaceWithinBrackets(seo.author, data);

            const seoMetaOgTitle = replaceWithinBrackets(seo.metaOg?.title, data);
            const seoMetaOgDescription = replaceWithinBrackets(
                seo.metaOg?.description,
                data
            );
            const seoMetaOgImage = replaceWithinBrackets(seo.metaOg?.image, data);

            const seoTwitterTitle = replaceWithinBrackets(
                seo.twitterCard?.title,
                data
            );
            const seoTwitterDescription = replaceWithinBrackets(
                seo.twitterCard?.description,
                data
            );
            const seoTwitterImage = replaceWithinBrackets(
                seo.twitterCard?.image,
                data
            );

            this.seo = {
                title: seoTitle,
                description: seoDescription || seoTitle,
                author: seoAuthor,
                metaOg: {
                    title: seoMetaOgTitle,
                    description: seoMetaOgDescription || seoMetaOgTitle,
                    image:  replaceWithinBrackets(seo?.metaOg?.image, data) ||content?.bannerImages?.desktop,
                },
                twitterCard: {
                    title: seoTwitterTitle,
                    description: seoTwitterDescription || seoTwitterTitle,
                    image: content?.bannerImages?.desktop,
                },
            };
        } else if (media && media.id) {
            // this.analyticsProperties.mediaId = data.media.id
            // this.analyticsPropertiess.setMediaId(data.media.id)
            this.structuredData.fromData(media);

            let description = removeHtmlTagsAndDecode(media.model?.description);

            const title = media.model?.title;

            let image = ''
            if (media.model.imageAssets && media.model.imageAssets.length > 0) {

                const lastIndex = media.model.imageAssets.length - 1
                image = media.model?.imageAssets[lastIndex].url;
            }

            if (!description) description = title;

            this.seo = {
                title: title || '',
                description: description,
                author: media.model?.author,
                metaOg: {
                    title: title,
                    description: description,
                    image: image,
                },
                twitterCard: {
                    title: title,
                    description: description,
                    image: image,
                },
            };
        } else {
            this.seo = seo;
        }

        if (this.seo && seo && seo.robot) {
            this.seo.robot = seo.robot
        }
    }

    getSeoData() {
        return this.seo
    }
}

export default SeoModel