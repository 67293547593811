

class PageView {
    constructor() {
        this.type = ""
        this.videoName = null
        this.live = false
    }

    setPageProperties(data) {

        const { media, content } = data ?? {}

        if (media) {
            this.type = "media"
            this.live = media?.model?.isLive
            this.videoName = media?.model?.title

        } else if (content) {
            this.type = "content"
        } else {
            this.type = "home"
        }

    }
}

export default PageView