
import { removeHtmlTagsAndDecode } from "./removeHtmlTagsAndDecode";

export const replaceWithinBrackets = (str, obj) => {
    const regex = /{([^}]+)}/g; // Match anything inside curly braces {}

    if (!str) return ''

    const replaced = str.replace(regex, (match, contents) => {
        const value = contents.split('.').reduce((acc, curr) => acc && acc[curr], obj);
        return value !== undefined ? removeHtmlTagsAndDecode(value) : match; // If value not found, keep original string
    });
    return replaced;
}