import { formatHeight } from '../../layout/style'

class Logo {
  constructor() {
    this.source = null
    this.url = null
    this.style = new StyleModel()
    this.linkType = 1
  }

  fromData(data){
    if(data.source){
      this.source = `${data.source}?q=90`
    }
    this.url = data.url
    this.linkType = data.linkType

    const  { width, height } = data
    const s = new StyleModel()
    s.fromQuery({ width, height })
    this.style = s
  }
}

class StyleModel {
  constructor() {
    this.height = null
    this.width = null
  }

  fromQuery({ width = null, height = null }) {
    // if (width) this.width = `${width}px`
    // if (height) this.height = formatHeight(height)    
    
    if (width) this.width = width
    if (height) this.height = height.value
  }
}

export default Logo