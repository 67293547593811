import Font from "../font/Font"
import Margin from "../layout/style/Margin"
import Padding from "../layout/style/Padding"
import PageMenuItem from "./PageMenuItem"
import Palette from "../palette/Palette"
import { formatIconStyle } from "~/helpers/icon/icon"
import { getBorder } from '@/models/general/BorderModel'

class PageMenu {
    constructor() {
        this.id = null
        this.style = {
            itemStyle: null,
            titleStyle: null,
            wrapperStyle: null,
            blurColor: null,
        }

        this.type = null
        this.items = null
        this.title = null
        this.inline = true
        this.showButtons = true
    }

    fromData(data) {
        this.id = data.id
        this.type = data.type
        this.title = data.props.title


        this.inline = data.props.menuType === 2

        const titleStyle = new Font()
        let itemStyle = new Font()
        let wrapperStyle = {}
        let iconSettings = {}
        let hoverColors = {}


        if (!this.inline) {
            wrapperStyle.justifyContent = data.style.alignment
        }

        if (data.icon) {
            iconSettings = formatIconStyle({ style: data.icon.style, props: { size: data.icon.props?.size } })
        }

        const palette1 = new Palette()
        palette1.checkColor(data.style.bgColorOnHover)
        hoverColors.backgroundColor = palette1.color

        const palette2 = new Palette()
        palette2.checkColor(data.style.fontHoverColor)
        hoverColors.color = palette2.color

        const palette3 = new Palette()
        palette3.checkColor(data.style.fontActiveColor)
        hoverColors.active = palette3.color


        if (data.style) {

            if (data.style.fontStyle) {
                itemStyle.fromData(data.style.fontStyle)
            }

            let wrapperStyleMargin = {}
            if (data.style.margin) {
                wrapperStyleMargin = new Margin(data.style.margin)
            }

            let wrapperStylePadding = {}
            if (data.style.padding) {

                wrapperStylePadding = new Padding(data.style.padding)
            }

            itemStyle.marginRight = `${data.style.gap}px`
            if (data.style?.border) {
                const { borderWidth, borderRadius, borderStyle, borderColor } = getBorder(data.style?.border)
                itemStyle.borderRadius = borderRadius
                itemStyle.borderWidth = borderWidth
                itemStyle.borderStyle = borderStyle

                // const palette4 = new Palette()
                // palette4.checkColor(borderColor)
                itemStyle.borderColor = borderColor
            }

            // const palette5 = new Palette()
            // palette5.checkColor(itemStyle.color)
            itemStyle.color = itemStyle.color

            const palette6 = new Palette()
            palette6.checkColor(data.style?.itemBackgroundColor)
            itemStyle.backgroundColor = palette6.color || 'white'

            const palette7 = new Palette()
            palette7.checkColor(data.style?.bgColor)
            wrapperStyle.backgroundColor = palette7.color

            wrapperStyle = {
                ...wrapperStyle,
                ...wrapperStyleMargin,
                ...wrapperStylePadding
            }
        }

        const palette8 = new Palette()
        palette8.checkColor(data.style.blurColor)

        this.style = {
            titleStyle,
            wrapperStyle,
            itemStyle,
            iconSettings,
            hoverColors,
            blurColor: palette8.color
        }

        // this.inline = data.style.orientation
        // this.showButtons = true

        const rawItems = data.props.menu?.items || data.props.items

        this.items = rawItems.map(item => {
            const pageMenuItem = new PageMenuItem()
            pageMenuItem.fromData(item)
            return pageMenuItem
        })
    }
}

export default PageMenu