<script setup>
import { JLabel } from 'media-flow-ui'
import { toRefs, computed } from 'vue'

import { formatFontStyle } from '~/models/layout/style/font'
import { formatComponentStyle } from '@/models/layout/style/component'

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: false,
  },
})

const { data } = toRefs(props)

const labelStyle = computed(() => ({
  ...formatFontStyle(data.value?.style?.fontProperties || {}),
}))

const style = computed(() =>
  formatComponentStyle({
    ...data.value?.style,
    fontProperties: null,
  })
)

const customWrapper = computed(() => {
  const { url = '', clickable = false, linkType = 1 } = data.value?.props
  if (clickable && url) {
    return linkType === 1 ? 'a' : 'RouterLink'
  }
  return 'div'
})
</script>

<template>
  <JLabel
    :text="data.props.text"
    :style="style"
    :label-style="labelStyle"
    :custom-wrapper="customWrapper"
    :url="data.props.url"
  />
</template>
