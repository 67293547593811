import MenuComponent from './MenuComponent';
import Font from './../../font/Font';
import MenuItem from './MenuItem';

class Menu {
    constructor() {
        this.style = null
        this.menuItems = []
    }

    fromData(data) {
        // const menuComponent = new MenuComponent()
        // menuComponent.fromData(data.menuComponent)
        // this.menuComponent = menuComponent
        const fontProperties = new Font()
        fontProperties.fromData(data.style.fontProperties)

        this.style = {
            textAlign: data.style.alignment,
            ...fontProperties,
            gap: `${data.style.spacing}px`
        }

        if (data.menuItems && data.menuItems.length > 0) {
            this.menuItems = data.menuItems.map(rawMenuItem => {
                const menuItem = new MenuItem()
                menuItem.fromData(rawMenuItem)
                return menuItem
            })
        }

    }
}

export default Menu