import { formatDate } from "~~/utils/formatDate"

class Search {
    constructor() {
        this.img = null
        this.source = '/'
        this.text = null
        this.date = null
        this.privacyStatusId = 3
        this.imageStyle = { aspectRatio: '16 / 9', borderRadius: '4px', border: 'solid 1px #b3b3b3' }
    }

    fromSearch(data) {

        if (data.imageAssets && data.imageAssets.length > 0) {
            this.img = data.imageAssets[0].url

        }

        if (data.cardImages) {
            this.img = data.cardImages.ar21_29
            this.imageStyle.aspectRatio = '21 / 29'
        }

        if (data.thumbnail) {
            this.img = data.thumbnail
            this.imageStyle.minWidth = '100px'

        }

        // if (data.isMedia) {
        //     this.imageStyle.minWidth = '100px'
        // } else {
        //     this.imageStyle.aspectRatio = '21 / 29'
        // }

        this.text = data.title

        if (data.url) {
            this.source = data.url
        }

        if (data.startDate) {

            try {
                this.date = formatDate(data.startDate)

            } catch (error) {
                console.error(error)
            }
        }

        this.privacyStatusId = data.privacyStatusId
    }
}

export default Search