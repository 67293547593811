export default {
  "layout": {
    "desktop": {
      rows: [
        {
          "id": "row-1678283731951309",
          "type": "row",
          "class": "!flex-nowrap",
          "children": [
            {
              "id": "col-1678283750109961",
              "type": "col",
              "children": [
                {
                  "id": "video-1678283783178844",
                  "type": "search",
                  "style": {
                    "width": 100,
                    "height": {
                      "type": "auto",
                      "value": 0
                    },
                    "alignment": "center",
                    "titleFontStyle": {
                      "size": 14,
                      "weight": "500",
                      "family": "Inter",
                      "style": "Normal",
                      "color": ""
                    },
                    "descriptionFontStyle": {
                      "size": 14,
                      "weight": "500",
                      "family": "Inter",
                      "style": "Normal",
                      "color": ""
                    },
                    "metricsFontStyle": {
                      "size": 14,
                      "weight": "500",
                      "family": "Inter",
                      "style": "Normal",
                      "color": ""
                    },
                    "buttonFontStyle": {
                      "size": 14,
                      "weight": "500",
                      "family": "Inter",
                      "style": "Normal",
                      "color": ""
                    }
                  },
                  "props": {
                    "bannerType": 1,
                    "data": {
                      "title": "Video Player",
                      "description": "",
                      "imgTo": "",
                      "action": "",
                      "text": "",
                      "textTo": "",
                      "img": "https://vpapps.blob.gjirafa.tech/ed8dcbec-f6c8-4723-83e1-6ceecc55dad2.jpeg",
                      "alt": ""
                    },
                    "settings": null
                  }
                },
              ],
              "style": {
                "order": 0,
                "display": true,
                "orientation": "vertical"
              },
              "props": {
                "columns": 12
              }
            }
          ],
          "props": {
            "yAlignment": null,
            "xAlignment": null
          },
          "style": {
            "spacing": 4,
            "isContainer": true
          }
        }
      ],
      style: {}
    },
    "tablet": {
      rows: [
        {
          "id": "row-1678283731951309",
          "type": "row",
          "class": "!flex-nowrap",
          "children": [
            {
              "id": "col-1678283750109961",
              "type": "col",
              "children": [
                {
                  "id": "video-1678283783178844",
                  "type": "search",
                  "style": {
                    "width": 100,
                    "height": {
                      "type": "auto",
                      "value": 0
                    },
                    "alignment": "center",
                    "titleFontStyle": {
                      "size": 14,
                      "weight": "500",
                      "family": "Inter",
                      "style": "Normal",
                      "color": ""
                    },
                    "descriptionFontStyle": {
                      "size": 14,
                      "weight": "500",
                      "family": "Inter",
                      "style": "Normal",
                      "color": ""
                    },
                    "metricsFontStyle": {
                      "size": 14,
                      "weight": "500",
                      "family": "Inter",
                      "style": "Normal",
                      "color": ""
                    },
                    "buttonFontStyle": {
                      "size": 14,
                      "weight": "500",
                      "family": "Inter",
                      "style": "Normal",
                      "color": ""
                    }
                  },
                  "props": {
                    "bannerType": 1,
                    "data": {
                      "title": "Video Player",
                      "description": "",
                      "imgTo": "",
                      "action": "",
                      "text": "",
                      "textTo": "",
                      "img": "https://vpapps.blob.gjirafa.tech/ed8dcbec-f6c8-4723-83e1-6ceecc55dad2.jpeg",
                      "alt": ""
                    },
                    "settings": null
                  }
                },
              ],
              "style": {
                "order": 0,
                "display": true,
                "orientation": "vertical"
              },
              "props": {
                "columns": 12
              }
            }
          ],
          "props": {
            "yAlignment": null,
            "xAlignment": null
          },
          "style": {
            "spacing": 4,
            "isContainer": true
          }
        }
      ],
      style: {}
    },
    "mobile": {
      rows: [
        {
          "id": "row-1678283731951309",
          "type": "row",
          "class": "!flex-nowrap",
          "children": [
            {
              "id": "col-1678283750109961",
              "type": "col",
              "children": [
                {
                  "id": "video-1678283783178844",
                  "type": "search",
                  "style": {
                    "width": 100,
                    "height": {
                      "type": "auto",
                      "value": 0
                    },
                    "alignment": "center",
                    "titleFontStyle": {
                      "size": 14,
                      "weight": "500",
                      "family": "Inter",
                      "style": "Normal",
                      "color": ""
                    },
                    "descriptionFontStyle": {
                      "size": 14,
                      "weight": "500",
                      "family": "Inter",
                      "style": "Normal",
                      "color": ""
                    },
                    "metricsFontStyle": {
                      "size": 14,
                      "weight": "500",
                      "family": "Inter",
                      "style": "Normal",
                      "color": ""
                    },
                    "buttonFontStyle": {
                      "size": 14,
                      "weight": "500",
                      "family": "Inter",
                      "style": "Normal",
                      "color": ""
                    }
                  },
                  "props": {
                    "bannerType": 1,
                    "data": {
                      "title": "Video Player",
                      "description": "",
                      "imgTo": "",
                      "action": "",
                      "text": "",
                      "textTo": "",
                      "img": "https://vpapps.blob.gjirafa.tech/ed8dcbec-f6c8-4723-83e1-6ceecc55dad2.jpeg",
                      "alt": ""
                    },
                    "settings": null
                  }
                },
              ],
              "style": {
                "order": 0,
                "display": true,
                "orientation": "vertical"
              },
              "props": {
                "columns": 12
              }
            }
          ],
          "props": {
            "yAlignment": null,
            "xAlignment": null
          },
          "style": {
            "spacing": 4,
            "isContainer": true
          }
        }
      ],
      style: {}
    },
  },
  "analyticsProperties": {
    "parents": {
      "TvShow": [
        "Alfabeti"
      ]
    }
  },
  "parents": null,
  "media": null,
  "queries": null,

  "customScripts": [],

  "seo": {
    "title": "",
    "author": "",
    "description": "",
    "copyright": "",
    "robot": {
      "follow": false,
      "noFollow": false,
      "index": false,
      "noIndex": false
    },
    "twitterCard": {
      "username": "",
      "title": "",
      "description": "",
      "image": null,
      "type": null
    },
    "metaOg": {
      "title": "",
      "description": "",
      "image": null
    }
  },
}