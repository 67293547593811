import { getDomainFromSubdomain } from "~~/utils/getDomainFromSubdomain";

class CookieStorage {
  getItem(key) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(key + "=")) {
        return cookie.substring(key.length + 1);
      }
    }
    return null;
  }

  setItem(key, value, expires = "") {
    let domain = getDomainFromSubdomain(location.origin);

    if (domain === "http://localhost:3000") {
      domain = "localhost";
    }

    console.log('SETTING ON COOKKIE, ', domain)

    document.cookie = `${key}=${value};${expires};domain=.${domain}`;
  }

  removeItem(key) {
    let domain = getDomainFromSubdomain(location.origin);

    if (domain === "http://localhost:3000") {
      domain = "localhost";
    }
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.${domain}`;
  }
}

export default CookieStorage;
