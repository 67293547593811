<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const customComponentRef = ref(null)


// onMounted(() => {
//   try {
//     const { data } = props

//     const link = data.props.styleSource ? [{
//       href: data.props.styleSource,
//       rel: "stylesheet",
//       type: "text/css",
//     }] : []

//     const script = data.props.scriptSources.length ? data.props.scriptSources.map(e => ({
//       src: e,
//       type: 'application/javascript',
//       tagPosition: 'head',
//       isSSR: true,
//     })) : []

//     useHead({
//       script,
//       link
//     })
//   } catch {} 
// })

onNuxtReady(async () => {
  try {
    const { data } = props

    const link = data.props.styleSource ? [{
      href: data.props.styleSource,
      rel: "stylesheet",
      type: "text/css",
    }] : []

    const script = data.props.scriptSources.length ? data.props.scriptSources.map(e => ({
      src: e,
      type: 'application/javascript',
      tagPosition: 'head',
      isSSR: true,
    })) : []

    useHead({
      script,
      link
    })
  } catch { }
})

</script>
<template>
  <div :id="data.props.id" ref="customComponentRef" :class="'order-' + data?.order" />
</template>