import { removeHtmlTagsAndDecode } from "~~/utils/removeHtmlTagsAndDecode"
import { secondsToISO8601 } from "~~/utils/secondsToISO8601"

class StructuredData {
    constructor() {
        this.name = null
        this.description = null
        this.thumbnailUrl = null
        this.uploadDate = null
        this.duration = null
        // this.contentUrl = null
        this.embedUrl = null
        this.publisher = null
    }

    fromData(data) {
        if (!data.model) return

        this.name = data.model.title

        this.description = removeHtmlTagsAndDecode(data.model.description)

        if (data.model.imageAssets && data.model.imageAssets.length > 0) {
            this.thumbnailUrl = data.model.imageAssets[0].url
        }
        this.uploadDate = data.model.publishDate
        this.duration = secondsToISO8601(data.model.length)

        // if (data.model.player && data.model.player.config && data.model.player.config.video) {
        //     this.contentUrl = data.model.player.config.video?.file
        // }

        // this.embedUrl = data.embedUrl
        this.publisher = data.model.author
        this.interactionStatistic = {
            "@type": "InteractionCounter",
            "interactionType": { "@type": "WatchAction" },
            "userInteractionCount": data.model.viewCount
        }
    }
}

export default StructuredData