import Palette from "../palette/Palette"

class Font {
  constructor() {
    this.fontSize = null
    this.color = null
    this.fontStyle = null
    this.fontWeight = null
    this.fontFamily = null
    this.textAlign = null
  }

  fromData({
    size = null,
    color = null,
    style = null,
    weight = null,
    family = null,
    textAlign = null,
    textAlignment = null,
    colorOnHover = null,
    colorOnActive = null,
    decoration = null,
    lineHeight = null,
  }) {

    if (size) this.fontSize = `${size}px`

    if (color) {
      const palette = new Palette()
      palette.checkColor(color)
      this.color = palette.color
    }

    this.fontStyle = style
    this.fontWeight = weight

    if (family && family != 'Default') {
      const formatFontName = family.replaceAll(' ', '-')
      this.fontFamily = `${formatFontName}, Avant !important`
    } else {
      delete this.fontFamily
    }

    if (textAlign) {
      this.textAlign = textAlign
    }

    if (textAlignment) {
      this.textAlign = textAlignment
    }

    if (colorOnHover) {
      // this.colorOnHover = colorOnHover
      const palette = new Palette()
      palette.checkColor(colorOnHover)
      this.colorOnHover = palette.color
    }


    if (colorOnActive) {
      // this.colorOnActive = colorOnActive
      const palette = new Palette()
      palette.checkColor(colorOnActive)
      this.colorOnActive = palette.color
    }

    if (decoration) {
      this.textDecorationLine = decoration
    }

    if (lineHeight) this.lineHeight = `${lineHeight}px`
  }
}

export default Font