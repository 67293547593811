import FooterRow from './FooterRow';
import Palette from '../palette/Palette';

class FooterWrapper {
    constructor() {
        this.style = null
        this.children = null
    }

    fromData(data) {

        const palette = new Palette()
        if (data?.style?.backgroundColor) {
            palette.checkColor(data.style.backgroundColor)
        }

        this.style = { ...data.style, backgroundColor: palette.color }

        this.children = data?.children.map(row => {
            const footerRow = new FooterRow()
            footerRow.fromData(row)
            return footerRow
        })
    }
}

export default FooterWrapper