import { isSubdomainUrl } from "./isSubdomainUrl"


const getDomainWithoutSubdomain = (url) => {
    // Use regex to extract the domain without the subdomain
    const regex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)(?:[/:?]|$)/i;
    const match = url.match(regex);

    if (match && match.length >= 2) {
        return match[1];
    } else {
        return null;
    }
}

export const getDomainFromSubdomain = subdomain => {
    if (isSubdomainUrl(subdomain)) {
        return subdomain.split('.').slice(1).join('.')
    }

    return getDomainWithoutSubdomain(subdomain)
}
