
// import IconStyle from 'models/general/IconStyle';
import IconSettings from './../../icon/IconSettings';
class Language {
    constructor() {
        this.iconSettings = null
    }

    fromData(data) {
        // TODO : change this
        const iconSettings = new IconSettings()
        iconSettings.fromData(data.iconSettings)
        this.iconSettings = iconSettings
    }
}

export default Language