<script setup>
import { JHeaderMenuV2 } from 'media-flow-ui'

import { formatMenuStyle } from '@/helpers/header/menu'
import { formatIconStyle } from '~/helpers/icon/icon'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  breakpoint: {
    type: String,
    required: true,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  onToggle: {
    type: Function,
    default: () => {},
  },
  onClose: {
    type: Function,
    default: () => {},
    required: false,
  },
  logoData: {
    type: [Object, null],
    default: null,
  },
  isNested: {
    type: Boolean,
    default: false,
  },
})

const formattedData = computed(() => {
  return {
    ...props.data,
    style: formatMenuStyle(
      { ...props.data.style, ...(props.isNested ? { width: '100%' } : {}) },
      props.data.props.layout
    ),
  }
})

const LOGO_DATA = computed(() => {
  if (props.logoData) {
    return {
      ...props.logoData.props,
      source: props.logoData.props.secondSource,
      customWrapper:
        props.logoData.props.linkType === 1 ? 'a' : defineNuxtLink({}),
    }
  }
})

const iconsStyle = formatIconStyle(props.data.icon)
</script>

<template>
  <JHeaderMenuV2
    :is-shown="isOpen"
    :is-open="isOpen"
    :on-toggle="onToggle"
    :on-menu-close="onClose"
    :breakpoint="breakpoint"
    :data="formattedData"
    :icons="iconsStyle"
    :menu-items="data.extras || formattedData.props.items || []"
    :logo="LOGO_DATA"
    fixed-wrapper-class="fixed"
    :is-nested-component="isNested"
    :class="'order-' + formattedData.order"
  />
</template>

<style></style>
