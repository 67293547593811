class Tab {
    constructor() {
        this.data = null
        this.layout = []
        this.settings = {}
        this.tabType = null
        this.isDynamic = null
    }

    fromData(data) {
        this.data = data.data
        this.layout = data.layout
        this.settings = data.settings
        this.tabType = data.tabType
        this.isDynamic = data.tabType === 2
    }
}

export default Tab