import AnalyticsUser from "./AnalyticsUser";
import Media from "./Media";
import PageView from "./PageView";

class AnalyticsProperties {
  constructor() {
    this.contentId = null;
    this.mediaId = null;
    this.projectId = null;
    this.organizationId = null;

    this.isEmbed = "vpapps/web-client";
    this.user = new AnalyticsUser();
    this.page = new PageView();
    this.server = {};
    this.device = "desktop";
    this.error = null;
    this.media = new Media()
    this.analyticsProperties = {}
    this.env = process.env.NODE_ENV
  }


  setUserToken(data) {
    this.user.setUserToken(data)
  }
  setUid(data) {
    this.user.setUid(data)
  }
  setExtraAnalyticsProperties(data) {
    this.analyticsProperties = data
  }

  setCustomProperties(data) {
    this.server = { ...data };
  }

  setMediaData(data){
    this.media.fromData(data)
  }

  setParentDataToMediaTags(data){
    this.media.setTags(data)
  }

  setPageProperties(data) {
    this.page.setPageProperties(data);
  }

  setContentId(data) {
    this.contentId = data;
  }

  setMediaId(data) {
    this.mediaId = data;
  }

  setProjectId(data) {
    this.projectId = data;
  }

  setOrganizationId(data) {
    this.organizationId = data;
  }

  setUserId(data) {
    this.user.setUserId(data);
  }

  setLoginStatus(data) {
    this.user.setLoginStatus(data);
  }

  setIsEmbed(data) {
    if (!data) return;

    if (data.includes("/embed")) {
      this.isEmbed = "vpapps/embed-client";
    } else {
      this.isEmbed = "vpapps/web-client";
    }
  }

  setDeviceType(data) {
    this.device = data;
  }

  setErrorType(data) {
    this.error = data;
  }
}

export default AnalyticsProperties;
