export default class EmptyLayout {
    constructor() {
        this.result = new Result()
    }
}

class Result {
    constructor() {
        this.layout = []
    }
}

class Layout {
    constructor() {
        this.layout = []
    }
}