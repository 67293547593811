<script setup>
import { JHeaderNotificationsV2 } from "media-flow-ui";

import { usePageBuilder } from "~/stores/page-builder";

// import { useAuthBuilder } from "~/stores/auth-builder";
import { useSignalrBuilder } from "~~/stores/signalr-builder";
import { useUrlBuilder } from "~/stores/url-builder";

import { storeToRefs } from "pinia";
import { ref, computed, watchEffect } from "vue";
import Notification from "~~/models/layout/notification/Notification";

import { SIGNALR_CONNECTION } from "~/constants/signalr/signalrConnections";

import { formatIconStyle } from "~/helpers/icon/icon"
import { formatNotificationsStyle } from "@/helpers/header/notifications";

const pageBuilderStore = usePageBuilder();

const urlBuilderStore = useUrlBuilder();
const { getProjectId } = storeToRefs(urlBuilderStore);

// const authBuilderStore = useAuthBuilder();
// const { user } = storeToRefs(authBuilderStore);

const signalrBuilderStore = useSignalrBuilder();
const { realTimeNotification } = storeToRefs(signalrBuilderStore);

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  breakpoint: {
    type: String,
    required: true,
  },
  isOpen: {
    type: Boolean,
    required: true,
  },
  onToggle: {
    type: Function,
    required: true,
  },
  onClose: {
    type: Function,
    default: () => {},
    required: false,
  },
});

const notificationData = ref([]);
const animateEl = ref("");

const skip = ref(0);
const take = ref(3);
const total = ref(null);

watch(
  () => realTimeNotification.value,
  (n) => {
    animateEl.value.classList.add("active");

    const notification = new Notification();

    notification.fromRealTime(n);

    notificationData.value.unshift(notification);

    setTimeout(() => {
      animateEl.value.classList.remove("active");
    }, 3000);
  }
);

onMounted(() => {
  signalrBuilderStore.setToJoinQueue(
    SIGNALR_CONNECTION.JOIN_NOTIFICATION,
    getProjectId.value
  );
});

onNuxtReady(() => {
  loadMoreComments();
});

const loadMoreComments = async () => {
  if (skip.value > total.value) return;

  const params = {
    take: take.value,
    skip: skip.value,
  };

  let response;

  try {
    response = await pageBuilderStore.actionGetNotifications(params);
  } catch (error) {
    console.error(error);
  }

  if (!response) return;

  total.value = response.total;

  skip.value += take.value;

  if (!response || response.length <= 0) return;

  notificationData.value.push(
    ...response.map((rawNotification) => {
      const notification = new Notification();
      notification.fromQuery(rawNotification);

      return {
        ...notification,
        text: notification.subtitle,
        id: notification.id,
        img: notification.img,
        name: notification.title,
        date: notification.date,
        redirectUrl: notification.redirectUrl,
        typeId: 1,
      };
    })
  );
};

const hasUnreadNotification = computed(() => {
  return notificationData.value.some((obj) => obj.read === false);
});

const onNotificationReadCallback = async (data) => {
  if (Number(data) === 0) {
    notificationData.value = notificationData.value.map((obj) => ({
      ...obj,
      read: true,
    }));
  } else {
    notificationData.value = notificationData.value.map((obj) => {
      if (obj.id === data) {
        return { ...obj, read: true };
      }
      return obj;
    });
  }
  const response = await pageBuilderStore.actionPutReadNotification(data);

  // Set isOpen to false and invoke the onToggle or onClose function
  props.onClose();
};

const formattedData = computed(() => {
  return {
    ...props.data,
    style: formatNotificationsStyle(props.data.style),
  };
});

const iconsStyle = formatIconStyle(props.data.icon)
</script>

<template>
  <div
    class="header-notification-wrapper relative block my-auto j-header-notifications"
    :class="'order-' + formattedData.order"
    ref="animateEl"
  >
    <!-- Move Wrapper inside ui library JHeaderNotifications.vue -->
    <span
      class="absolute flex h-2 w-2 -right-1 top-0 z-10"
      v-if="hasUnreadNotification"
    >
      <span
        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"
      />
      <span class="relative inline-flex rounded-full h-2 w-2 bg-red-500" />
    </span>

    <!-- {{ formattedData }} -->

    <JHeaderNotificationsV2
      :is-open="isOpen"
      :on-toggle="onToggle"
      :on-close="onClose"
      :breakpoint="breakpoint"
      :data="formattedData"
      :icons="iconsStyle"
      :notification-data="notificationData"
      :on-notification-read-callback="onNotificationReadCallback"
    />
  </div>
</template>

<style lang="scss">
.header-notification-wrapper {
  svg {
    // width: 30px;
    // height: 30px;
    transition: all 0.4s ease-in;
  }

  &.active {
    svg {
      animation: tilt-shaking 0.25s infinite;
    }
  }
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(7deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-7deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
