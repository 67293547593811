import FooterWrapper from "./FooterWrapper"

class Footer {
    constructor(deviceType) {
        this.deviceType = deviceType
        this.desktop = null
        this.mobile = null
        this.tablet = null
    }

    fromData(data) {

        if (!data) {
            throw ('No Footer data')
        }

        const deviceType = this.deviceType

        if (data[deviceType]) {
            const footerWrapper = new FooterWrapper()
            footerWrapper.fromData(data[deviceType])


            // this[deviceType] = footerWrapper

            this[deviceType] = {
                children: footerWrapper?.children,
                style: footerWrapper?.style
            }
        }

        // if (data.desktop) {
        //     const desktopFooterWrapper = new FooterWrapper()
        //     desktopFooterWrapper.fromData(data.desktop)
        //     this.desktop = desktopFooterWrapper
        // }

        // if (data.mobile) {
        //     const mobileFooterWrapper = new FooterWrapper()
        //     mobileFooterWrapper.fromData(data.mobile)
        //     this.mobile = mobileFooterWrapper
        // }

        // if (data.tablet) {
        //     const tabletFooterWrapper = new FooterWrapper()
        //     tabletFooterWrapper.fromData(data.tablet)
        //     this.tablet = tabletFooterWrapper
        // }
    }
}

export default Footer