
import IconSettings from './../../icon/IconSettings';

class Notification {
    constructor() {
        this.componentName = null
        this.display = false
        this.name = null
        this.iconSettings = null
    }

    fromData(data) {
        this.componentName = data.componentName
        this.display = data.display
        this.name = data.name
        // TODO: change this
        const iconSettings = new IconSettings()
        iconSettings.fromData(data.iconSettings)
        this.iconSettings = iconSettings
    }
}

export default Notification