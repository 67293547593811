import { formatHeight, formatWidth } from '~/models/layout/style'

import Palette from '~/models/palette/Palette'
import { formatFontStyle } from "~/models/layout/style/font"
import { getBorder } from '@/models/general/BorderModel'

class ChatRow {
  constructor() {
    this.wrapper = new WrapperModel()
    this.image = new ImageModel()
    this.author = new TextModel()
    this.text = new TextModel()
  }

  fromData({ wrapper = null, image = null, author = null, text = null }) {
    if (wrapper) {
      const w = new WrapperModel()
      w.fromData(wrapper)
      this.wrapper = w
    }

    if (image) {
      const i = new ImageModel()
      i.fromData(image)
      this.image = i
    }

    if (author) {
      const a = new TextModel()
      a.fromData(author)
      this.author = a
    }

    if (text) {
      const a = new TextModel()
      a.fromData(text)
      this.text = a
    }
  }
}

class WrapperModel {
  constructor() { }
  fromData({
    backgroundColor = '',
    mentionColor = '',
    backgroundColorOnHover = '',
    backgroundColorOnActive = '',
    alignment = '',
    gap = 0,
    width = null,
    height = null,
    margin = null,
    padding = null,
    borderProperties = null,

  }) {

    if (gap) this.gap = `${gap}px`
    if (alignment) this.textAlign = alignment
    if (backgroundColor) {
      const palette = new Palette()
      palette.checkColor(backgroundColor)
      this.backgroundColor = palette.color
    }

    if (mentionColor) {
      const palette = new Palette()
      palette.checkColor(mentionColor)
      this.mentionColor = palette.color
    }

    if (backgroundColorOnHover) {
      const palette = new Palette()
      palette.checkColor(backgroundColorOnHover)
      this.backgroundColorOnHover = palette.color
    }

    if (backgroundColorOnActive) {
      const palette = new Palette()
      palette.checkColor(backgroundColorOnActive)
      this.backgroundColorOnActive = palette.color
    }

    if (borderProperties) {
      const { borderRadius, borderColor, borderStyle, borderWidth } = getBorder(borderProperties)
      this.borderRadius = borderRadius

      this.borderColor = borderColor

      this.borderStyle = borderStyle || 'solid'
      this.borderWidth = borderWidth
    }

    if (padding)
      this.padding = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`

    if (margin)
      this.margin = `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`

    if (width) this.width = formatWidth(width)

    if (height) this.height = formatHeight(height)

  }
}

class ImageModel {
  constructor() {
    this.width = '16px'
  }
  fromData({
    objectFit = 'cover',
    type = 'rounded',
    width = null,
    blur = null,
  }) {
    this.objectFit = objectFit

    const hasBorder = type === 'rounded'
    if (hasBorder) this.borderRadius = '100%'

    if (width) this.width = formatWidth(width)
    if (width) this.minWidth = formatWidth(width)
    if (width) this.height = formatWidth(width)
    if (blur) this.filter = `blur(${formatWidth(blur)})`

  }
}

class TextModel {
  constructor() { }

  fromData({ fontProperties = null }) {
    if (fontProperties) {
      const font = formatFontStyle(fontProperties)
      const {
        fontSize,
        fontWeight,
        fontFamily,
        fontStyle,
        textDecorationLine,
        textAlign,
        lineHeight,
        color,
        colorOnHover,
        colorOnActive,
      } = font

      if (fontSize) this.fontSize = fontSize
      if (fontWeight) this.fontWeight = fontWeight
      if (fontFamily) this.fontFamily = fontFamily
      if (fontStyle) this.fontStyle = fontStyle
      if (textDecorationLine) this.textDecorationLine = textDecorationLine
      if (textAlign) this.textAlign = textAlign
      if (lineHeight) this.lineHeight = lineHeight

      if (color) {
        this.color = color
      }
      if (colorOnActive) {
        this.colorOnActive = colorOnActive
      }
      if (colorOnHover) {
        this.colorOnHover = colorOnHover
      }
    }
  }
}

export default ChatRow