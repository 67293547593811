    
<script setup>
import { JHeaderLanguageV2 } from "media-flow-ui";

import { formatLanguagesStyle } from '@/helpers/header/languages'

import { formatIconStyle } from "~/helpers/icon/icon"

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  breakpoint: {
    type: String,
    required: true,
  },
  isOpen: {
    type: Boolean,
    required: true,
  },
  onToggle: {
    type: Function,
    required: true,
  },
  onClose: {
    type: Function,
    default: () => {},
    required: false,
  },
})

  const formattedData = computed(() => {
  return {
    ...props.data,
    style: formatLanguagesStyle(props.data.style, props.data.props.layout),
  }
})

const iconsStyle = formatIconStyle(props.data.icon)
// const authBuilderStore = useAuthBuilder();

// const { user } = storeToRefs(authBuilderStore);

</script>

<template>
  <JHeaderLanguageV2
    :is-open="isOpen"
    :on-toggle="onToggle"
    :on-close="onClose"
    :breakpoint="breakpoint"
    :data="formattedData"
    :icons="iconsStyle"
    :class="'order-'+data.order"
  />
</template>

<style>
</style>