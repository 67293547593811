
import IconSettings from './../../icon/IconSettings';

class Profile {
    constructor() {
        this.name = null
        this.componentName = null
        this.display = false

        this.iconSettings = null
    }

    fromData(data) {
        this.name = data.name
        this.componentName = data.componentName
        this.display = data.display

        // TODO : change this
        const iconSettings = new IconSettings()
        iconSettings.fromData(data.iconSettings)
        this.iconSettings = iconSettings
    }
}

export default Profile