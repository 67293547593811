import Palette from '~/models/palette/Palette'
import { getBorder } from '@/models/general/BorderModel'

class ChatWrapper {
  constructor() {
  }

  fromData({
    gap = 12,
    backgroundColor = '',
    padding = null,
    margin = null,
    borderProperties = null,
  }) {
    this.gap = `${gap}px`

    if (backgroundColor) {
      const palette = new Palette()
      palette.checkColor(backgroundColor)
      this.backgroundColor = palette.color
    }

    if (borderProperties) {
      const { borderRadius, borderColor, borderStyle, borderWidth } = getBorder(borderProperties)
      this.borderRadius = borderRadius

      // const palette2 = new Palette()
      // palette2.checkColor(borderColor)
      this.borderColor = borderColor

      this.borderStyle = borderStyle || 'solid'
      this.borderWidth = borderWidth
    }

    if (padding)
      this.padding = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`

    if (margin)
      this.margin = `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`
  }
}

export default ChatWrapper