import { defineStore, storeToRefs } from "pinia"

import { usePageBuilder } from "~/stores/page-builder";
import { useUrlBuilder } from "~/stores/url-builder";

export const useGlobalBuilder = defineStore({
    id: 'global-builder',
    state: () => {
        return {
            isLoading: false,
            isRedirectingToLogin: false,
        }

    },
    actions: {
        SET_IS_REDIRECTING_TO_LOGIN(data) {
            // const router = useRouter();

            // router.push('/auth/porta');

            // this.isRedirectingToLogin = data
        },
        async globalReq(e) {
        },
    },
    getters: {
    }
})