import Author from "./Author"
import Reply from "./Reply"
import { formatFontStyle } from "~/models/layout/style/font"
import { getBorder } from "../../general/BorderModel"
import Palette from "~/models/palette/Palette"
class Comments {
  constructor() {
    this.id = null

    this.author = new Author()

    this.createdAt = null
    this.createdBy = null
    this.dislikeCount = null
    this.isDisliked = false
    this.likeCount = null
    this.mediaId = null
    this.projectId = null
    this.repliesCount = null
    this.text = null
    this.replies = []
    this.parentId = null
    this.canDelete = false

    //to remove
    this.img = null
  }

  fromData(data) {
    this.id = data.id

    const author = new Author()
    author.fromData(data.author)
    this.author = author

    this.createdAt = data.createdAt
    this.createdBy = data.createdBy
    this.dislikeCount = data.dislikeCount
    this.isDisliked = data.isDisliked
    this.isLiked = data.isLiked
    this.likeCount = data.likeCount
    this.mediaId = data.mediaId
    this.projectId = data.projectId
    this.repliesCount = data.repliesCount
    this.text = data.text
    this.canDelete = data.canDelete

    if (data.replies) {
      this.replies = data.replies.map((rawReply) => {
        const reply = new Reply()
        reply.fromData(rawReply)
        return reply
      })
    }

    //to remove
    this.img = data.author?.picture
  }
}

export class CommentsComponentModel {
  constructor() {
    this.iconColor = ""
    this.backgroundColor = ""
    this.gap = null
    this.padding = null
    this.margin = null
    this.borderStyle = "solid"
    this.borderColor = null
    this.borderWidth = null
    this.borderRadius = null
    this.fontStyle = null
  }

  fromData({
    bgColor = "",
    padding = null,
    margin = null,
    borderProperties = null,
    fontProperties = null,
    spacing = null,
  }) {
    const palette = new Palette()
    palette.checkColor(bgColor)
    this.backgroundColor = palette.color

    if (spacing) this.gap = `${spacing}px`

    if (padding)
      this.padding = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`

    if (margin)
      this.margin = `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`

    if (borderProperties) {
      const { borderColor, borderStyle, borderRadius, borderWidth } =
        getBorder(borderProperties)
      this.borderStyle = borderStyle
      this.borderWidth = borderWidth
      this.borderRadius = borderRadius
      this.borderColor = borderColor
    }

    if (fontProperties) {
      this.fontStyle = formatFontStyle(fontProperties)
    }
  }
}

export function formatCommentsComponentStyle(data) {
  const comp = new CommentsComponentModel()

  if (data) comp.fromData(data)

  return { ...comp, ...comp.fontStyle }
}

export default Comments
