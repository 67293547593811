import ChatAuthor from "./ChatAuthor";

class ChatMessage {
  constructor() {
    this.id = null;
    this.chatMessageId = null;
    this.author = new ChatAuthor();
    this.message = null;
    this.isPinned = false;
    this.chatMessageId = null;
    this.createdAt = null;
    this.userMentions = [];
  }

  formData(data) {
    this.id = data.id;
    this.chatMessageId = data.id || data.chatMessageId;
    this.message = data.message;
    this.isPinned = data.isPinned;
    this.userMentions = data.userMentions || [];
    if (data.createdAt) {
      let date = new Date(data.createdAt);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;

      this.createdAt = `${hours}:${minutes}`;
    } else {
      this.createdAt = data.createdAt;
    }

    const author = new ChatAuthor();
    author.fromData(data.author);
    this.author = author;
  }
}

export default ChatMessage;
