import Components from './Components';
import Palette from '../palette/Palette';

class NavigationBarStyle {

    constructor() {
        this.style = null

        this.components = null
    }

    fromData(data) {

        const palette = new Palette()
        palette.checkColor(data?.backgroundColor)

        this.style = {
            backgroundColor: palette.color
        }

        const components = new Components()
        components.fromData(data.components)
        this.components = components
    }
}

export default NavigationBarStyle