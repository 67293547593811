class Details {
    constructor(){
        this.id = null
    }

    fromData(data){
        this.id = data.id
    }
}

export default Details