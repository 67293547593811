import Font from "../font/Font"
import { formatIconStyle } from "~/helpers/icon/icon"

class IconStyle {
    constructor() {
        this.id = null
        this.style = {
            itemStyle: null,
            titleStyle: null,
            wrapperStyle: null,
            iconSettings: {
                size: '40px'
            }
        }

        this.type = null
        this.title = null
        // this.inline = true
        this.showButtons = true
    }

    fromData(data) {

        const titleStyle = new Font()
        let itemStyle = new Font()
        let wrapperStyle = {}
        let iconSettings = {
            size: '40px',
            color:'red'
        }

        let hoverColors = {}


        if (data) {
            const formatedIconStyle = formatIconStyle(data)
            iconSettings = formatedIconStyle.style
        }

        this.style = {
            titleStyle,
            wrapperStyle,
            itemStyle,
            iconSettings,
            hoverColors
        }

    }
}

export default IconStyle