class MenuItem {
    constructor() {
       this.navigationLabel = null
       this.url = '/'
       this.order = 0
       this.itemTypeId = null
       this.itemType = null
       this.childItems = [] 
    }

    fromData(data) {

        this.navigationLabel = data?.navigationLabel
        this.url = data?.url ? data?.url : '/'
        this.order = data?.order
        this.itemTypeId = data?.itemTypeId
        this.itemType = data?.itemType
        

        if(data?.childItems?.length > 0){
            this.childItems = data.childItems.map(rawChildItem => {
                const childItem = new MenuItem()
                childItem.fromData(rawChildItem)
                return childItem
            })
        }
    }
}

export default MenuItem