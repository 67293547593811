import { usePageBuilder } from "~/stores/page-builder";

export const useHandleResize = () => {
  // console.error("useHandleResize....")

  const pageBuilderStore = usePageBuilder();
  pageBuilderStore.UPDATE_WINDOW_WIDTH(window.innerWidth);

  window.addEventListener("resize", () => {
    pageBuilderStore.UPDATE_WINDOW_WIDTH(window.innerWidth);
  });

}