<script setup>
import {
  JHeaderSceleton,
  JHeaderV2,
  JHeaderLanguageV2,
  JHeaderLogoV2,
} from "media-flow-ui"
import { storeToRefs } from "pinia"
import { usePageBuilder } from "~/stores/page-builder"
import { ref, computed } from "vue"
import { useUrlBuilder } from "~/stores/url-builder"
import { useAuthBuilder } from "~/stores/auth-builder"

import { $$t, changeLanguage } from "~~/utils/i19m"

import { formatHeaderStyle } from "@/helpers/header/header"
import Palette from "~/models/palette/Palette"

const props = defineProps({
  headerData: {
    type: Object,
    required: true,
  },
})

const pageBuilderStore = usePageBuilder()
const urlBuilder = useUrlBuilder()

const { header, deviceType, pageType } = storeToRefs(pageBuilderStore)

const { getToken, getProjectId } = storeToRefs(urlBuilder)

const searchValue = ref("")
const jHeader = ref(null)

onMounted(() => {
  const route = useRoute()
  // console.log(route)
  if (route.path === "/results") {
    jHeader.value.onSearchToggle()
  }
})

const onSearchEnterClick = (data) => {
  const router = useRouter()

  const encodeStringUrl = encodeURIComponent(data)
  router.push(`/results?query=${encodeStringUrl}`)
}

const searchInputFormat = (isFocused) => {
  const route = useRoute()
  if (route.path === "/results") {
    searchValue.value = route.query?.query
    return true
  }

  return isFocused
}

searchInputFormat()

const logoData = {
  id: "logo-1689756510897642",
  type: "logo",
  props: {
    source:
      "https://cdn-dev.vpapps.cloud/jzpEGA8MnmX/JkvEjBQEXV6/2d195900-2069-41a8-8d8b-f3f2f7e05512.png",
    linkType: 2,
    url: "/",
    secondSource: "",
  },
  style: {},
  icon: null,
  entityId: null,
}

const getComponentData = (data) => {
  const componentsData = props.headerData[deviceType.value].components.find(
    (c) => c.type === data
  )

  return componentsData
}

const isOpen = ref(true)
const onToggle = () => (isOpen.value = !isOpen.value)

// order-0 order-1 order-2 order-3 order-4 order-5 order-6 order-7 order-8 order-9 order-10

const headerBackground = computed(() => {
  if (
    pageType.value == "search" &&
    (getProjectId.value === "DZeEDLVEw1d" ||
      getProjectId.value === "dV8MmwVEG2X" ||
      getProjectId.value === "4k6EbkwN230" ||
      getProjectId.value === "xj9NRWKMzm8" ||
      getProjectId.value === "JkvEjBQEXV6" ||
      getProjectId.value === "JkvEjrYEXV6")
  )
    return "#6E3CFF"
  else if (
    pageType.value == "search" &&
    (getProjectId.value === "Z6wMB4kEl2n" ||
      getProjectId.value === "lWjM5lgNAO7")
  )
    return "#FFFFFF"

  const palette = new Palette()
  palette.checkColor(props.headerData[deviceType.value].style?.backgroundColor)
  return palette.color
})

const style = computed(() => {
  return formatHeaderStyle(props.headerData[deviceType.value].style)
})

const isStickyHeader = computed(() => {
  return props.headerData[deviceType.value].style?.sticky
    ? "position:fixed;top:0;width:100%"
    : ""
})
</script>

<template>
  <!-- <pre class="text-red-200">
  {{ headerData }}
</pre> -->
  <div
    class="vp-header relative z-[110]"
    :class="[
      deviceType,
      {
        'backdrop-contrast-50': pageType == 'search',
      },
    ]"
    :style="isStickyHeader"
  >
    <div class="loading-sceleton" v-if="!headerData?.[deviceType]?.components">
      <JHeaderSceleton />
    </div>
    <div v-else class="flex">
      <!-- <Head>
        <Link v-if="headerData?.settings?.logo.source" rel="preload" :href="headerData?.settings?.logo.source"
          as="image" type="image/svg+xml" />
      </Head> -->

      <JHeaderV2
        :breakpoint="deviceType"
        :components="headerData[deviceType].components"
        :style="{
          ...style,
          backgroundColor: headerBackground,
        }"
        :class="'meni'"
        class="z-10"
        ref="jHeader"
      >
        <template #logo="{ isOpen, onToggle, item }">
          <HeaderV2Logo
            :breakpoint="deviceType"
            :is-open="isOpen"
            :data="item"
            :on-toggle="onToggle"
          />
        </template>
        <template #search="{ isOpen, onToggle, onClose, item }">
          <HeaderV2Search
            :breakpoint="deviceType"
            :is-open="isOpen"
            :data="item"
            :searchEnterCallback="onSearchEnterClick"
            :clientSearchValue="searchValue"
            :on-toggle="onToggle"
            :on-close="onClose"
            :noSearchDataText="$$t('component_search_no_results')"
          />
        </template>

        <template #menu="{ isOpen, onToggle, onClose, item }">
          <HeaderV2Menu
            v-show="!jHeader?.isSearchOpen"
            :breakpoint="deviceType"
            :is-open="isOpen"
            :data="item"
            :logo-data="getComponentData('logo')"
            :on-toggle="onToggle"
            :on-close="onClose"
            :class="isOpen ? 'burger-menu' : ''"
          />
        </template>

        <template #notifications="{ isOpen, onToggle, onClose, item }">
          <HeaderV2Notification
            v-if="getToken"
            :breakpoint="deviceType"
            :is-open="isOpen"
            :data="item"
            :on-toggle="onToggle"
            :on-close="onClose"
          />
        </template>

        <template #profile="{ isOpen, onToggle, onClose, item }">
          <HeaderV2Profile
            :breakpoint="deviceType"
            :is-open="isOpen"
            :data="item"
            :on-toggle="onToggle"
            :on-close="onClose"
          />
        </template>

        <template #languages="{ isOpen, onToggle, onClose, item }">
          <HeaderV2Language
            :breakpoint="deviceType"
            :is-open="isOpen"
            :data="item"
            :on-toggle="onToggle"
            :on-close="onClose"
          />
        </template>

        <template #spacing="{ isOpen, onToggle, item }">
          <HeaderV2Spacing
            :breakpoint="deviceType"
            :is-open="isOpen"
            :data="item"
            :on-toggle="onToggle"
          />
        </template>

        <template #label>
          <HeaderV2Label :data="getComponentData('label')" />
        </template>

        <template #sidebar="{ isOpen, onToggle, onClose, item }">
          <HeaderV2Sidebar
            :breakpoint="deviceType"
            :is-open="isOpen"
            :data="item"
            :on-toggle="onToggle"
            :on-close="onClose"
          />
        </template>

        <template #customComponent>
          <HeaderV2CustomComponent
            class="transition"
            :class="jHeader?.isSearchOpen && '!w-0 !overflow-hidden'"
            :data="getComponentData('customComponent')"
          />
        </template>
      </JHeaderV2>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep(.burger-menu) {
  .nav-open-right {
    z-index: 100 !important;
  }
}
</style>
