class ChatBody {
  constructor() {
    this.backgroundColor = ""
    this.color = ""
  }

  fromData(data) {
    this.backgroundColor = ""
    this.color = ""
  }
}

export default ChatBody