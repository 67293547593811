
export const secondsToISO8601 = secs => {

    if(!secs) return null

    let durationInSeconds = Math.floor(secs)
    let hours = Math.floor(durationInSeconds / 3600);
    let minutes = Math.floor((durationInSeconds % 3600) / 60);
    let seconds = durationInSeconds % 60;
    
    return `PT${hours.toString().padStart(2, '0')}H${minutes.toString().padStart(2, '0')}M${seconds.toString().padStart(2, '0')}S`;
    
}