export class DimensionValues {
  constructor() {
    this.top = 0
    this.right = 0
    this.bottom = 0
    this.left = 0
  }

  fromData({ top = 0, right = 0, bottom = 0, left = 0 }) {
    this.top = top
    this.right = right
    this.bottom = bottom
    this.left = left
  }

  getDimensions() {
    return `${this.top}px ${this.right}px ${this.bottom}px ${this.left}px`
  }

  getMargin() {
    return {
      margin: `${this.top}px ${this.right}px ${this.bottom}px ${this.left}px`,
    }
  }

  getPadding() {
    return {
      padding: `${this.top}px ${this.right}px ${this.bottom}px ${this.left}px`,
    }
  }

  getBorderRadius() {
    return {
      borderRadius: `${this.top}px ${this.right}px ${this.bottom}px ${this.left}px`,
    }
  }

  getBorderWidth() {
    return {
      borderWidth: ` ${this.top}px ${this.right}px ${this.bottom}px ${this.left}px`,
    }
  }
}
