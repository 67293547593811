import { formatWidth, formatHeight } from '~/models/layout/style';
import Palette from "~/models/palette/Palette";

export class SpacingStyleModel {
  constructor() {
    this.maxWidth = '100%'
    this.maxHeight = 'auto'
  }

  fromData({ width = null, height = null }) {
    if (width) this.maxWidth = formatWidth(width)

    if (height) this.maxHeight = formatHeight(height)
  }
}

export class DividerWrapperStyleModel {
  constructor() {
    this.padding = null
    this.margin = null
  }

  fromData({ padding = null, margin = null }) {
    if (padding)
      this.padding = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`
    if (margin)
      this.margin = `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`
  }
}

export class DividerStyleModel {
  constructor() {
    // this.width = '100%'
    this.borderColor = ''
    this.borderStyle = 'solid'
  }

  fromData({
    width = null,
    height = null,
    color = '',
    borderStyle = 'solid',
    direction = 'horizontal',
  }) {
    if (direction === 'horizontal') {
      this.width= formatWidth(width || {})
      this.borderBottomWidth = formatHeight(height || {})
    } else if (direction === 'vertical') {
      this.borderLeftWidth = formatWidth(width || {})
      this.height = formatHeight(height || {})
    }

    if (color) {
      const palette = new Palette();
      palette.checkColor(color);
      this.borderColor = palette.color;
    }
    this.borderStyle = borderStyle
  }
}
