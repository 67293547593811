import FooterComponent from './FooterComponent';

class FooterColumn {
    constructor(){
        this.id = null
        this.type = null
        this.style = null
        this.children = null
        this.props = null
    }

    fromData(data){
        this.id = data.id
        this.type = data.type
        this.style = data.style
        this.colStyle = {
            padding: `${data.style.padding.top}px ${data.style.padding.right}px ${data.style.padding.bottom}px ${data.style.padding.left}px`,
            margin: `${data.style.margin.top}px ${data.style.margin.right}px ${data.style.margin.bottom}px ${data.style.margin.left}px`
        }
        this.children = data?.children?.map(label => {
            const comp = new FooterComponent() 
            comp.fromData(label)

            return comp
        })
        this.props = data.props
    }
}

export default FooterColumn