import NavigationBarProperties from './NavigationBarProperties';
import HeaderComponents from './HeaderComponents';

class HeaderWrapper {
    constructor() {
        this.navigationBarProperties = null
        this.components = null
    }

    fromData(data) {
        const navigationBarProperties = new NavigationBarProperties()
        navigationBarProperties.fromData(data.navigationBarProperties)
        this.navigationBarProperties = navigationBarProperties


        const hcs = new HeaderComponents()
        
        hcs.fromData(data.components)
        this.components = hcs

    }
}

export default HeaderWrapper