import Author from "./Author"
class Reply {
    constructor(){
        this.id = null

        this.author = new Author()

        this.createdAt = null
        this.createdBy = null
        this.dislikeCount = null
        this.isDisliked = null
        this.isLiked = false
        this.likeCount = null
        this.mediaId = null
        this.projectId = null
        this.repliesCount = null
        this.text = null
        this.replies = null
        this.parentId = null
        this.canDelete = false
    }

    fromData(data){
        this.id = data.id

        const author = new Author()
        author.fromData(data.author)
        this.author = author

        this.createdAt = data.createdAt
        this.createdBy = data.createdBy
        this.dislikeCount = data.dislikeCount
        this.isDisliked = data.isDisliked
        this.isLiked = data.isLiked
        this.likeCount = data.likeCount
        this.mediaId = data.mediaId
        this.projectId = data.projectId
        this.repliesCount = data.repliesCount
        this.text = data.text
        this.replies = data.replies
        this.parentId = data.parentId
        this.canDelete = data.canDelete

    }
}

export default Reply