<script setup>
import { $$t } from "@/utils/i19m";
// import apm from "elastic-apm-node";

const props = defineProps({
  error: Object,
});

if (process.server) {
  const apm = require("elastic-apm-node");

  const customError = new Error(props.error?.message, props.error);

  customError.stack = props.error.stack;

  if (apm.isStarted()) {
    apm.captureError(customError);
  }
}

const handleError = () => clearError({ redirect: "/" });
</script>


<template>
  <div class="not-found-page not-found-container container mx-auto text-center">
    <Head>
      <Title>{{ error.statusCode }}</Title>
      <Meta
        name="description"
        :content="$$t('page_server_error_description')"
      />
    </Head>
    <pre class="text-white">{{ error }}</pre>

    <h1>{{ error.statusCode }}</h1>
    <p>
      {{ $$t("page_server_error_description") }}
    </p>
    <button @click="handleError">Clear errors</button>
  </div>
</template>