<script setup>
import { JHeaderSpacingV2 } from 'media-flow-ui'

import {
  formatSpacingStyle,
  formatDividerStyle,
} from '@/helpers/spacing/spacing'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const { data } = props

const style = computed(() => formatSpacingStyle(data.style, data.props.type))
const dividerStyle = computed(() => formatDividerStyle(data.style))
</script>
<template>
  <JHeaderSpacingV2
    :class="'order-' + data?.order"
    :style="style"
    :divider-style="dividerStyle"
    :is-divider="data.props.type === 'divider'"
  />
</template>
