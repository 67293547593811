<script setup>
import { storeToRefs } from 'pinia'
import { usePageBuilder } from '~/stores/page-builder'
import { JFooter, JFooterSceleton, JRow, JCol } from 'media-flow-ui'

import { useUrlBuilder } from '~/stores/url-builder'

const props = defineProps({
  footerData: {
    type: Object,
    required: false
  }
})


// children: state.footer[state.deviceType]?.children,
// style: state.footer[state.deviceType]?.style,

const urlBuilder = useUrlBuilder()
const { getProjectId } = storeToRefs(urlBuilder)

const pageBuilderStore = usePageBuilder()

const { deviceType } = storeToRefs(pageBuilderStore)

const COMPONENT_NAMES = {
  image: 'FooterImage',
  label: 'FooterLabel',
  logo: 'FooterLogo',
  menu: 'FooterMenu',
  spacing: 'FooterSpacing',
  SocialMedia: 'FooterSocialMedia',
}
</script>

<template>
  <div class="vp-footer relative">

    <div v-if="footerData">
        <JFooter ref="jFooter" :style="footerData[deviceType]?.style">
          <j-row v-for="row in footerData[deviceType]?.children" :key="row.id" :x-alignment="row.props.xAlignment"
            :y-alignment="row.props.yAlignment" :is-container="true">
            <j-col v-for="col in row?.children" :key="col.id" :columns="col.props.columns"
              :orientation="col.style?.orientation" :style="col.colStyle">
              <Identifier v-for="component in col.children" :component="{
                ...component,
                type: COMPONENT_NAMES[component.type],
              }" :key="component.id" />
            </j-col>
          </j-row>
        </JFooter>
    </div>
  </div>
</template>

<style></style>
