
export const removeHtmlTagsAndDecode = text => {

    if (!text) return null

    text = text.replace(/<[^>]*>/g, '');

    // Decode Czech characters
    const decodingTable = {
        '&aacute;': 'á',
        '&Aacute;': 'Á',
        '&yacute;': 'ý',
        '&Yacute;': 'Ý',
        '&iacute;': 'í',
        '&Iacute;': 'Í',
        '&eacute;': 'é',
        '&Eacute;': 'É',
        '&scaron;': 'š',
        '&Scaron;': 'Š',
        '&ccaron;': 'č',
        '&Ccaron;': 'Č',
        '&rcaron;': 'ř',
        '&Rcaron;': 'Ř',
        '&ncaron;': 'ň',
        '&Ncaron;': 'Ň',
        '&uring;': 'ů',
        '&Uring;': 'Ů',
        '&zcaron;': 'ž',
        '&Zcaron;': 'Ž'
    };
    for (let key in decodingTable) {
        if (text)
            text = text.replace(new RegExp(key, 'g'), decodingTable[key]);
    }

    return text;
}