class ChatAuthor {
    constructor() {
        this.name = null
        this.image = null
        this.userId = null
    }

    fromData(data) {
        this.name = `${data.firstName} ${data.lastName}`
        this.image = data.picture
        this.userId = data.userId
    }
}

export default ChatAuthor