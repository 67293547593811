import Palette from "../palette/Palette"

class IconSettings {
    constructor() {
        this.name = null
        this.size = null
        this.color = null
    }

    fromData(data) {
        this.name = data.name
        this.size = `${data.size}px`

        const palette = new Palette()
        palette.checkColor(data.color)
        this.color = palette.color
    }
}

export default IconSettings